import React from 'react';
import { compose, pure } from 'recompose';
import parseTrackingData from '../../../../../../../shared/helpers/parseTrackingData';
import { ensureTeaserInterface } from '../../../../components/Teaser/shared/helpers';
import withAppNexus from '../../../../../../shared/decorators/withAppNexus';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Logo from '../../../../components/Logo';
import TeaserGrid from '../../../../components/TeaserGrid';
import StatusPage from './../../../StatusPage';
import { ADVERTISING_TYPE_BRANDREPORT } from '../../../../../../../shared/constants/content';
import { ROOT_SCHEMA_TYPE_WEBSITE } from '../../../../../../../shared/constants/structuredData';
import { LOGO_BRAND_REPORT } from '../../../../components/Logo/constants';
import { GRID_LAYOUT_TEASER_3X2 } from '../../../../components/TeaserGrid/gridConfigs/constants';
import sections from '../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';

export const getDefaultTitle = () => 'BrandReport';

const Sponsors = ({ data }) => {
  const sponsorPage = data?.environment?.routeByPath?.object;

  if (!data || typeof sponsorPage !== 'object') {
    return <StatusPage />;
  }

  const sponsors =
    (Array.isArray(data?.environment?.sponsors?.edges) &&
      data?.environment?.sponsors?.edges?.length > 0 &&
      data?.environment?.sponsors?.edges) ||
    null;

  return (
    <div className={`sponsor-overview ${styles.Wrapper}`}>
      <div className={styles.HeaderBackground}>
        <div className={sections.Container}>
          {sponsorPage?.activeMenuTrail && (
            <Breadcrumbs
              pageUrl={sponsorPage.preferredUri}
              items={sponsorPage.activeMenuTrail}
              title={sponsorPage.title || getDefaultTitle()}
            />
          )}
          <div className={styles.BrandReportWrapper}>
            <div className={styles.BrandReportLogoWrapper}>
              <Logo
                type={LOGO_BRAND_REPORT}
                origin={ADVERTISING_TYPE_BRANDREPORT}
              />
            </div>
          </div>
          <h1 className={styles.SecondaryTitle}>
            {sponsorPage.shortTitle || sponsorPage.title || ''}
          </h1>
          <p className={styles.HeaderDescription}>{sponsorPage.lead || ''}</p>
        </div>
      </div>
      {sponsors && (
        <TeaserGrid
          layout={GRID_LAYOUT_TEASER_3X2}
          items={ensureTeaserInterface(sponsors)}
        />
      )}
    </div>
  );
};

export default pure(
  compose(
    withHelmet({
      getNode: (mapProps) => mapProps?.data?.routeByPath?.object || null,
      getFallbackTitle: (mapProps) => (!!mapProps && getDefaultTitle()) || '',
      rootSchemaType: ROOT_SCHEMA_TYPE_WEBSITE,
    }),
    withAppNexus({
      parseTrackingData: (props) =>
        parseTrackingData({
          ...props,
          articleType: 'Sponsors',
        }),
    }),
  )(Sponsors),
);
