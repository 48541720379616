import { ComponentType } from 'react';
import { connect } from 'react-redux';
import navigationUserMenuFactory from '../../../../../../../common/components/NavigationUserMenu/factory';
import { setNavigationVisible } from '../../../../../../../shared/actions/navigation';
import Icon from '../../../Icon';
import MenuItem from './components/MenuItem';
import { ROUTE_ALERTS, ROUTE_BOOKMARKS } from '../../../../constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const closeNavigationByProps =
  ({ setNavigationVisible }) =>
  () => {
    setNavigationVisible(null);
  };

const links = [
  {
    name: 'Mes favoris',
    link: `/${ROUTE_BOOKMARKS}`,
    iconType: 'IconBookmark',
    trackingClass: 'link-usercockpit-bookmarks',
  },
  {
    name: 'Alertes par e-mail',
    link: `/${ROUTE_ALERTS}`,
    iconType: 'IconBell',
    trackingClass: 'link-usercockpit-email-alerts',
  },
  {
    name: 'e-Paper',
    link: 'https://epaper.pme.ch',
    iconType: 'IconNewspaper',
    trackingClass: 'link-usercockpit-epaper',
  },
  {
    name: 'Abos et Services',
    link: __AUTH0_SERVICES_URI__,
    iconType: 'IconServices',
    trackingClass: 'link-usercockpit-account',
  },
  {
    name: 'Modification du profil',
    link: __AUTH0_PROFILE_URI__,
    iconType: 'IconGear',
    trackingClass: 'link-usercockpit-profile',
  },
];

const NavigationUserMenu: ComponentType<any> = navigationUserMenuFactory({
  Icon,
  MenuItem,
  MenuHeaderLogo: null,
  links,
  closeNavigation: closeNavigationByProps,
  appWelcomeMessage: 'Bonjour',
  appLogoutMessage: 'Déconnexion',
  appCloseMenuMessage: 'Fermer le menu',
  styles: {
    Wrapper: styles.Wrapper,
    MenuHeader: styles.MenuHeader,
    MenuHeaderContent: styles.MenuHeaderContent,
    MenuBodyWrapper: '',
    UserInformationWrapper: styles.UserInformationWrapper,
    UserName: styles.UserName,
    UserCredentials: styles.UserCredentials,
    MenuWrapper: styles.MenuWrapper,
    Container: grid.Container,
    MenuItem: styles.MenuItem,
    CloseButton: styles.CloseButton,
    CloseIcon: styles.CloseIcon,
  },
});

const mapDispatchToProps = {
  setNavigationVisible,
};

export default connect(null, mapDispatchToProps)(NavigationUserMenu);
