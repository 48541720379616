import React, { ReactElement, memo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { VideoType } from '../../../../../../../shared/helpers/createVideoObjectJsonLd';
import { ensureVideoItem } from '../../../../../../../shared/helpers/ensureVideo';
import { latestNativeAdvertisingsGenerator } from '../../../../../../../shared/helpers/latestNativeAdvertisings';
import { mapDataForInfobox } from '../../../../../../../shared/helpers/mapDataForInfoBox';
import { latestNACounter } from '../../../../../../../shared/helpers/useLatestNativeAdvertisings';
import { findFirstParagraphIdByType } from '../../../../../../../shared/helpers/utils';
import { getScrollOffset } from '../../../../../../shared/helpers/getScrollOffset';
import windowStateSelector from '../../../../../../../shared/selectors/windowStateSelector';
import useRecommendations from '../../../../../../../shared/hooks/useRecommendations';
import PianoTemplateParagraph from '../../../../../../../common/components/Paragraphs/components/PianoTemplateParagraph';
import SmoothScroll from '../../../../../../../common/components/SmoothScroll';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import AppNexus from '../../../AppNexus';
import Error from '../../../Error';
import ArticleRecommendations from '../../../Recommendations/components/ArticleRecommendations';
import AdvantagesParagraph from '../AdvantagesParagraph';
import BlockquoteParagraph from '../BlockquoteParagraph';
import ContentStageParagraph from '../ContentStageParagraph';
import EmbedParagraph from '../EmbedParagraph';
import EntityQueueParagraph from '../EntityQueueParagraph';
import HeroMediaParagraph from '../HeroMediaParagraph';
import ImageGalleryParagraph from '../ImageGalleryParagraph';
import ImageParagraph from '../ImageParagraph';
import InfoBoxParagraph from '../InfoBoxParagraph';
import LinkBoxParagraph from '../LinkBoxParagraph';
import ListicleItemParagraph from '../ListicleItemParagraph';
import MinistageParagraph from '../MinistageParagraph';
import MultiColumnParagraph from '../MultiColumnParagraph';
import NativeAdvertisingCarouselParagraph from '../NativeAdvertisingCarouselParagraph';
import ParallaxImageParagraph from '../ParallaxImageParagraph';
import RankingListParagraph from '../RankingListParagraph';
import SectionParagraph from '../SectionParagraph';
import TeaserStageParagraph from '../TeaserStageParagraph';
import TextParagraph from '../TextParagraph';
import VideoParagraph from '../VideoParagraph';
import WebformParagraph from '../WebformParagraph';
import { ParagraphIndexContext } from '../../../../../../../shared/context/paragraphs';
import {
  EMBED_WIDTH_FULL,
  EMBED_WIDTH_GRID,
} from '../../../../../../../common/components/Paragraphs/components/EmbedParagraph/constants';
import { INFO_BOX_TYPE } from '../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/constants';
import { RENDER_AD_TYPE_RECOS } from '../../../../../../../shared/constants/ads';
import { VIDEO_CONTENT_TYPE } from '../../../../../../../shared/constants/content';
import {
  ADVANTAGES_PARAGRAPH,
  AD_PARAGRAPH,
  BLOCKQUOTE_PARAGRAPH,
  CONTENT_STAGE_PARAGRAPH,
  EMBED_PARAGRAPH,
  ENTITY_QUEUE_PARAGRAPH,
  HERO_MEDIA_PARAGRAPH,
  IMAGE_GALLERY_PARAGRAPH,
  IMAGE_PARAGRAPH,
  INFOBOX_PARAGRAPH,
  INPUT_FORM_PARAGRAPH,
  LINK_BOX_PARAGRAPH,
  LISTICLE_ITEM_PARAGRAPH,
  MINISTAGE_PARAGRAPH,
  MULTI_COLUMNS_PARAGRAPH,
  NATIVE_ADVERTISING_CAROUSEL_PARAGRAPH,
  PARALLAX_IMAGE_PARAGRAPH,
  PIANO_TEMPLATE_PARAGRAPH,
  RANKING_LIST_PARAGRAPH,
  SECTION_PARAGRAPH,
  TEASER_STAGE_PARAGRAPH,
  TEXT_PARAGRAPH,
  TEXT_PARAGRAPH_INFOBOX_STYLE_VALUE,
  VIDEO_PARAGRAPH,
} from '../../../../../../../shared/constants/paragraphs';
import { PUBLICATION_GROUP_PME } from '../../../../../../../shared/constants/publications';
import { RECOMMENDATION_OPERATION } from '../../../../../../../shared/constants/recommendations';
import {
  PAGESCREEN_MARKETING_TYPE,
  PAGE_SCREEN_HERO_MEDIA_TYPE,
} from '../../../../screens/PageScreen/constants';
import { SPONSOR_DEFAULT_TYPE } from '../../../../screens/Sponsor/constants';
import { VIDEO_PAGE } from '../../../../screens/Video/constants';
import { IAV_1, IAV_2, MMR_1 } from '../../../AppNexus/constants';
import { ARTICLE_RECOMMENDATION_TITLE } from './constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../../../../common/assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import { RecommendationsNode } from '../../../../../../../shared/hooks/useRecommendations/typings';
import { ParagraphsRendererProps } from './typings';

type ParagraphsRendererPropsInner = ParagraphsRendererProps & {
  viewportLabel?: string;
};

type AdZoneProps = {
  adSlots?: Array<{
    slotName: string;
    deviceType: 'mobile' | 'tabletDesktop';
    key: string;
  }>;
};

let latestNAGenerator: Generator<RecommendationsNode> = null;
let listicleIndex: number;

const ParagraphsRenderer = (
  props: ParagraphsRendererPropsInner,
): ReactElement => {
  const {
    pageBody,
    hasContainer = true,
    paragraphsForFree = null,
    origin,
    viewportLabel,
    isMarketingPageReducedHeader,
  } = props;

  const { recommendations, fetchRecommendations } = useRecommendations();

  const fetchRecommendationsRef = useRef(fetchRecommendations);
  const recommendationsLimit = latestNACounter(pageBody);

  // reset listicle index on every render
  listicleIndex = -1;

  useEffect(() => {
    if (recommendationsLimit <= 0) {
      return;
    }

    fetchRecommendationsRef.current({
      publication: PUBLICATION_GROUP_PME,
      articleKeywords: {},
      contentId: '1', // random number as it gets igonred by mostread anyway
      operation: RECOMMENDATION_OPERATION.LATEST_NATIVE_ADVERTISINGS,
      limit: recommendationsLimit,
    });
  }, [recommendationsLimit]);

  if (!pageBody || !Array.isArray(pageBody) || pageBody.length < 1) {
    return null;
  }

  latestNAGenerator = latestNativeAdvertisingsGenerator(recommendations);

  const isEmptyCarousel = (entry) =>
    entry.__typename === NATIVE_ADVERTISING_CAROUSEL_PARAGRAPH &&
    (entry.nativeAdvertising?.edges === null ||
      (Array.isArray(entry.nativeAdvertising?.edges) &&
        entry.nativeAdvertising?.edges.length === 0));

  return (
    <>
      {pageBody.map((entry, index): ReactElement => {
        if (!entry || isEmptyCarousel(entry)) {
          return null;
        }

        if (entry.__typename === INPUT_FORM_PARAGRAPH && !entry.webform) {
          return;
        }

        const isFirstParagraph = index === 0;

        const hasContainerClass =
          hasContainer &&
          entry.__typename !== CONTENT_STAGE_PARAGRAPH &&
          entry.__typename !== ENTITY_QUEUE_PARAGRAPH &&
          entry.__typename !== LINK_BOX_PARAGRAPH &&
          entry.__typename !== INFOBOX_PARAGRAPH &&
          entry.__typename !== MINISTAGE_PARAGRAPH &&
          entry.__typename !== ADVANTAGES_PARAGRAPH &&
          entry.__typename !== PARALLAX_IMAGE_PARAGRAPH &&
          entry.__typename !== LISTICLE_ITEM_PARAGRAPH &&
          entry.__typename !== HERO_MEDIA_PARAGRAPH &&
          !(
            entry.__typename === TEXT_PARAGRAPH &&
            entry?.styleValue === TEXT_PARAGRAPH_INFOBOX_STYLE_VALUE
          ) &&
          !(
            entry.__typename === TEXT_PARAGRAPH &&
            origin === SPONSOR_DEFAULT_TYPE
          ) &&
          !(
            entry.__typename === EMBED_PARAGRAPH &&
            entry.embedWidth === EMBED_WIDTH_FULL
          );
        const withContainerClass = {
          [grid.Container]: hasContainerClass,
        };

        const hasToRenderRecosForDeviceTypes =
          (entry.adSlots?.length > 0 &&
            entry.adSlots?.reduce((acc, adSlot) => {
              if (adSlot.slotName === RENDER_AD_TYPE_RECOS) {
                acc.push(adSlot.deviceType);
              }
              return acc;
            }, [])) ||
          [];

        const scrollOffset = getScrollOffset(viewportLabel);

        const paragraphsJsx = (
          <div key={`paragraphs-${entry.id}-${index}`} id={entry.id}>
            <ParagraphIndexContext.Provider value={index}>
              <SmoothScroll
                offset={scrollOffset}
                anchorId={getAnchorIdByNode(entry)}
              >
                <>
                  <div className={getSectionForNode(entry, props)}>
                    <div
                      className={classNames(
                        'paragraph-wrapper',
                        styles.Paragraphs,
                        withContainerClass,
                      )}
                    >
                      {wrapGridForElement(
                        entry,
                        isFirstParagraph,
                        hasContainerClass,
                        props,
                        isMarketingPageReducedHeader,
                      )}
                    </div>
                  </div>

                  {!props.isAdSuppressed && origin !== INFO_BOX_TYPE && (
                    <AdZoneFinal adSlots={entry.adSlots || []} />
                  )}

                  {props.contentGcid &&
                    hasToRenderRecosForDeviceTypes.length > 0 && (
                      <div
                        className={classNames(
                          'recommendation-slot',
                          getSectionForNode(entry, props),
                          styles.SectionDefaultMargin,
                          grid.HideForPrint,
                          {
                            [styles.SectionDefaultMarginTop]:
                              entry.__typename === TEXT_PARAGRAPH &&
                              !entry.isLastOfGroup,
                            'recommendation-slot-mobile':
                              hasToRenderRecosForDeviceTypes.includes('mobile'),
                            'recommendation-slot-tabletDesktop':
                              hasToRenderRecosForDeviceTypes.includes(
                                'tabletDesktop',
                              ),
                          },
                        )}
                      >
                        <ArticleRecommendations
                          contentGcid={props.contentGcid}
                          articleKeywords={props.articleKeywords}
                          publication={'PME'}
                          articleColStyle={props.colStyle}
                          title={ARTICLE_RECOMMENDATION_TITLE}
                          origin={origin}
                          nativeAdvertisingConfig={[1, 2]}
                        />
                      </div>
                    )}
                </>
              </SmoothScroll>
            </ParagraphIndexContext.Provider>
          </div>
        );

        if (paragraphsForFree !== null && index >= paragraphsForFree) {
          return (
            <div
              key={`paragraph-paywall-${entry.id || index}`}
              className={`restricted-section-${index + 1}`}
            >
              {paragraphsJsx}
            </div>
          );
        }

        return (
          <div
            key={`paragraph-section-${entry.id || index}`}
            className={classNames({
              [`section-${index + 1}`]: paragraphsForFree !== null,
            })}
          >
            {paragraphsJsx}
          </div>
        );
      })}
    </>
  );
};

// TODO: as the "parentRenderer" logic is broken on the stack -
// we simply don't know which cases shall NOT have a margin
const hasSectionDefaultMarginByOrigin = (origin: string) =>
  origin !== VIDEO_PAGE;

const getSectionForNode = (
  { __typename, isLastOfGroup = false, text },
  {
    addSectionClass = '',
    origin,
    isAdSuppressed,
    hasTwoColumns,
  }: ParagraphsRendererPropsInner,
): string => {
  switch (__typename) {
    case AD_PARAGRAPH:
      return (!isAdSuppressed && 'ad-wrapper') || '';
    case EMBED_PARAGRAPH:
      return sections.Section;
    case INPUT_FORM_PARAGRAPH:
      return classNames({
        [styles.SectionDefaultMargin]: hasSectionDefaultMarginByOrigin(origin),
        [styles.InputFormOnHeroMedia]:
          origin === PAGE_SCREEN_HERO_MEDIA_TYPE && !hasTwoColumns,
      });
    case TEXT_PARAGRAPH:
      return classNames(sections.Section, {
        [addSectionClass]: !!addSectionClass,
        [styles.SectionDefaultMargin]:
          isLastOfGroup && hasSectionDefaultMarginByOrigin(origin),
        [styles.MarketingPage]: origin === PAGESCREEN_MARKETING_TYPE,
      });
    case BLOCKQUOTE_PARAGRAPH:
      if (!text) {
        return '';
      }
    default:
      return classNames(sections.Section, {
        [addSectionClass]: !!addSectionClass,
        [styles.SectionDefaultMargin]: hasSectionDefaultMarginByOrigin(origin),
        [styles.MarketingPage]: origin === PAGESCREEN_MARKETING_TYPE,
      });
  }
};

const getAnchorIdByNode = (entry) => {
  let anchorId: string = entry?.anchorId || '';

  if (!anchorId && entry?.__typename === INPUT_FORM_PARAGRAPH) {
    anchorId = entry.id;
  }

  return anchorId;
};

const wrapGridForElement = (
  entry,
  isFirstParagraph: boolean,
  hasContainerClass: boolean,
  props: ParagraphsRendererPropsInner,
  isMarketingPageReducedHeader: boolean,
): ReactElement => {
  const paragraphType: string = props.origin || entry.__typename;
  const jsx = getComponentForEntry(
    entry,
    isFirstParagraph,
    props,
    isMarketingPageReducedHeader,
  );
  if (
    hasContainerClass === false ||
    paragraphType === MINISTAGE_PARAGRAPH ||
    paragraphType === INFOBOX_PARAGRAPH ||
    paragraphType === PARALLAX_IMAGE_PARAGRAPH ||
    entry.__typename === TEASER_STAGE_PARAGRAPH ||
    (entry.__typename === EMBED_PARAGRAPH &&
      [EMBED_WIDTH_FULL, EMBED_WIDTH_GRID].includes(entry.embedWidth))
  ) {
    return <TestFragment data-testid="paragraph-item">{jsx}</TestFragment>;
  }

  return (
    <div className={grid.Row} data-testid="paragraph-item">
      <div className={props.colStyle}>
        <div>{jsx}</div>
      </div>
    </div>
  );
};

const getComponentForEntry = (
  entry,
  isFirstParagraph = false,
  props: ParagraphsRendererPropsInner,
  isMarketingPageReducedHeader: boolean,
): ReactElement => {
  const scrollOffset = getScrollOffset(props.viewportLabel);
  switch (entry.__typename) {
    case TEXT_PARAGRAPH:
      if (entry.styleValue === TEXT_PARAGRAPH_INFOBOX_STYLE_VALUE) {
        return (
          <TestFragment data-testid="paragraphsrenderer-info-box-paragraph-wrapper">
            <InfoBoxParagraph
              infoBoxParagraph={mapDataForInfobox(entry)}
              articleColStyle={props.colStyle}
              origin={props.origin}
            />
          </TestFragment>
        );
      } else {
        return (
          <TestFragment data-testid="paragraphsrenderer-text-paragraph-wrapper">
            <TextParagraph
              isFirst={isFirstParagraph && props.showCap}
              addClass={props.addClass}
              origin={props.origin}
              textParagraph={entry}
            />
          </TestFragment>
        );
      }
    case IMAGE_PARAGRAPH:
      return <ImageParagraph imageParagraph={entry} origin={props.origin} />;
    case IMAGE_GALLERY_PARAGRAPH:
      return <ImageGalleryParagraph gallery={entry} origin={props.origin} />;
    case BLOCKQUOTE_PARAGRAPH:
      return <BlockquoteParagraph blockquoteParagraph={entry} />;
    case EMBED_PARAGRAPH:
      return <EmbedParagraph embedParagraph={entry} />;
    case PIANO_TEMPLATE_PARAGRAPH:
      return <PianoTemplateParagraph pianoTemplateParagraph={entry} />;
    case ENTITY_QUEUE_PARAGRAPH:
      return (
        <EntityQueueParagraph
          entityQueue={entry}
          origin={props.origin}
          isFirst={
            findFirstParagraphIdByType(
              props.pageBody,
              ENTITY_QUEUE_PARAGRAPH,
            ) === entry.id
          }
          latestNAGenerator={latestNAGenerator}
        />
      );
    case CONTENT_STAGE_PARAGRAPH:
      return <ContentStageParagraph stage={entry} isFirst={isFirstParagraph} />;
    case LINK_BOX_PARAGRAPH:
      return <LinkBoxParagraph linkBox={entry} />;
    case MINISTAGE_PARAGRAPH:
      return (
        <MinistageParagraph
          ministageParagraph={entry}
          origin={props.origin}
          colStyle={props.colStyle}
          scrollOffset={scrollOffset}
        />
      );
    case TEASER_STAGE_PARAGRAPH:
      return <TeaserStageParagraph teaserStage={entry} origin={props.origin} />;
    case INFOBOX_PARAGRAPH:
      return (
        <InfoBoxParagraph
          origin={props.origin}
          infoBoxParagraph={entry}
          articleColStyle={props.colStyle}
        />
      );
    case VIDEO_PARAGRAPH:
    case VIDEO_CONTENT_TYPE:
      const ensuredVideo: { video: VideoType } = ensureVideoItem(entry);
      return (
        ensuredVideo.video && (
          <VideoParagraph
            video={ensuredVideo.video}
            origin={props.origin}
            suppressSource={entry.suppressSource}
          />
        )
      );
    case MULTI_COLUMNS_PARAGRAPH:
      return (
        <TestFragment data-testid="paragraphsrenderer-multicolumsparagraph-wrapper">
          <MultiColumnParagraph
            multiColumnParagraph={entry}
            origin={props.origin}
          />
        </TestFragment>
      );
    case NATIVE_ADVERTISING_CAROUSEL_PARAGRAPH:
      return (
        <NativeAdvertisingCarouselParagraph
          nativeAdvertisingCarouselParagraph={entry}
        />
      );
    case PARALLAX_IMAGE_PARAGRAPH:
      return <ParallaxImageParagraph parallaxImageParagraph={entry} />;
    case RANKING_LIST_PARAGRAPH:
      return <RankingListParagraph rankingList={entry} />;
    case INPUT_FORM_PARAGRAPH:
      if (entry && typeof entry === 'object' && entry.webform) {
        return (
          <WebformParagraph
            webform={entry.webform}
            anchorId={getAnchorIdByNode(entry)}
          />
        );
      }
      return null;
    case SECTION_PARAGRAPH:
      return (
        <TestFragment data-testid="paragraphsrenderer-sectionparagraph-wrapper">
          <SectionParagraph
            paragraph={entry}
            activeChannelTitle={props.activeChannel}
            colStyle={props.colStyle}
            origin={`SECTION_PARAGRAPH_${props.origin}`}
          />
        </TestFragment>
      );
    case LISTICLE_ITEM_PARAGRAPH:
      listicleIndex += 1;

      return (
        <TestFragment data-testid="paragraphsrenderer-listicleitem-paragraph-wrapper">
          <ListicleItemParagraph
            listicleItem={entry}
            listicleIndex={listicleIndex}
            scrollOffset={scrollOffset}
          />
        </TestFragment>
      );
    case ADVANTAGES_PARAGRAPH:
      return <AdvantagesParagraph entry={entry} />;
    case HERO_MEDIA_PARAGRAPH:
      return (
        <HeroMediaParagraph
          entry={entry}
          isCentered={isMarketingPageReducedHeader}
        />
      );
    // let all other cases fall through to default case
    default:
      // eslint-disable-next-line no-underscore-dangle
      return __DEVELOPMENT__ ? (
        <Error msg={`Paragraphs: No Component for: ${entry.__typename}`} />
      ) : null;
  }
};

const AdZone = ({ adSlots }: AdZoneProps): ReactElement => {
  if (adSlots.length === 0) {
    return null;
  }

  const jsx = adSlots.map((adSlot) => {
    if (adSlot.slotName === RENDER_AD_TYPE_RECOS) {
      return null;
    }

    const isIAVSlot = [IAV_1, IAV_2].includes(adSlot.slotName);

    return (
      <span
        className={styles.AdZone}
        key={`${adSlot.slotName}-${Math.floor(Math.random() * 10000)}`}
      >
        <div
          className={classNames(
            'ad-wrapper',
            styles.AdPadding,
            (adSlot.deviceType && `ad-wrapper-${adSlot.deviceType}`) || '',
          )}
        >
          <div className={grid.Container}>
            <div
              className={classNames(styles.AdWrapper, {
                [styles.IAVWrapper]: isIAVSlot,
              })}
            >
              <TestFragment
                data-testid="paragraphsrenderer-ad-wrapper"
                data-slot={adSlot.slotName}
              >
                <AppNexus
                  slot={adSlot.slotName}
                  isMultiPlacement={adSlot.slotName !== MMR_1}
                  deviceType={adSlot.deviceType}
                />
              </TestFragment>
            </div>
          </div>
        </div>
      </span>
    );
  });

  return <>{jsx}</>;
};
const AdZoneFinal = memo(AdZone);

const mapStateToProps = (state: Record<string, any>): Record<string, any> => ({
  viewportLabel: windowStateSelector(state).viewport.label,
});

export default connect(mapStateToProps)(ParagraphsRenderer);
