import React, { Fragment, ReactElement } from 'react';
import { connect } from 'react-redux';
import { getScrollOffset } from '../../../../../../shared/helpers/getScrollOffset';
import windowStateSelector from '../../../../../../../shared/selectors/windowStateSelector';
import Link from '../../../../../../../common/components/Link';
import SmoothScroll from '../../../../../../../common/components/SmoothScroll';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import Icon from '../../../Icon';
import ParagraphsRenderer from '../ParagraphsRenderer';
import styles from './styles.legacy.css';
// @ts-ignore
import grid from '@grid.legacy.css';
import { SectionParagraphProps } from './typings';

type SectionParagraphPropsInner = SectionParagraphProps & {
  viewportLabel?: string;
};

const SectionParagraph = ({
  paragraph,
  activeChannelTitle,
  origin,
  colStyle,
  viewportLabel,
}: SectionParagraphPropsInner): ReactElement | null => {
  if (!paragraph) {
    return null;
  }

  const scrollOffset = getScrollOffset(viewportLabel);

  return (
    <Fragment key={`section-paragraph-${paragraph?.id || paragraph?.title}`}>
      <SmoothScroll anchorId={paragraph?.title} offset={scrollOffset}>
        <section data-testid="explaining-article-section">
          {paragraph?.title && (
            <div
              className={grid.Container}
              data-testid="explaining-article-section-title-wrapper"
            >
              <div className={grid.Row}>
                <div className={colStyle}>
                  {(paragraph?.link?.path && (
                    <Link path={paragraph.link.path} className={styles.Link}>
                      <h3
                        className={styles.Title}
                        data-testid="explaining-article-section-title"
                      >
                        {paragraph.title}
                        <Icon
                          addClass={styles.LinkIndicator}
                          type="IconChevronRight"
                        />
                      </h3>
                    </Link>
                  )) || (
                    <h3
                      className={styles.Title}
                      data-testid="explaining-article-section-title"
                    >
                      {paragraph.title}
                    </h3>
                  )}
                </div>
              </div>
            </div>
          )}
          <TestFragment data-testid="explaining-article-section-body">
            <ParagraphsRenderer
              showCap={false}
              pageBody={paragraph?.body || paragraph}
              origin={origin}
              activeChannel={activeChannelTitle || ''}
              colStyle={colStyle}
            />
          </TestFragment>
        </section>
      </SmoothScroll>
    </Fragment>
  );
};

const mapStateToProps = (state: Record<string, any>): Record<string, any> => ({
  viewportLabel: windowStateSelector(state).viewport.label,
});

export default connect(mapStateToProps)(SectionParagraph);
