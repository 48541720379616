import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import lodashSlice from 'lodash/slice';
import { enrichArticleBodyWithADs } from '../../../../../shared/helpers/ads';
import { isNativeAdvertising as isNativeAdvertisingFunction } from '../../../../../shared/helpers/sharePanel';
import authStateSelector from '../../../../../shared/selectors/authStateSelector';
import locationStateSelector from '../../../../../shared/selectors/locationStateSelector';
import { WithHeaderProps } from '../../../../../shared/decorators/@types/withHeader';
import withHeaderProps from '../../../../shared/decorators/withHeaderProps';
import withHelmet from '../../../../shared/decorators/withHelmet';
import {
  resetHeaderData,
  setHeaderData,
} from '../../../../../shared/actions/header';
import useImpressionTracking from '../../../../../shared/hooks/useImpressionTracking';
import SmoothScroll from '../../../../../common/components/SmoothScroll';
import AppNexus from '../../components/AppNexus';
import Comments from '../../components/Comments';
import EditButtons from '../../components/EditButtons';
import MagazineIssueSection from '../../components/MagazineIssueSection';
import Paragraphs from '../../components/Paragraphs';
import ArticleAlerts from '../Article/components/ArticleAlerts';
import ArticleHeader from '../Article/components/ArticleHeader';
import DossierFooter from '../Article/components/DossierFooter';
import RecommendedContentSection from '../Article/components/RecommendedContentSection';
import AuthorsTeaser from './components/AuthorsTeaser';
import { MMR_1 } from '../../../../../shared/constants/adZone';
import {
  COMMENTS_ANCHOR_ID,
  COMMENT_STATUS_HIDDEN,
} from '../../../../../shared/constants/comments';
import {
  RESTRICTION_STATUS_PAID,
  RESTRICTION_STATUS_REGISTERED,
} from '../../../../../shared/constants/content';
import {
  PIANO_CONTAINER_INLINED,
  PIANO_PLACEHOLDER_INLINED,
} from '../../../../../shared/constants/piano';
import {
  RECOMMENDATION_OPERATION,
  RECOMMENDATION_TYPE,
} from '../../../../../shared/constants/recommendations';
import { ROOT_SCHEMA_TYPE_NEWS_ARTICLE } from '../../../../../shared/constants/structuredData';
import { TRACKING_CLASS_ARTICLE_BODY } from '../../../../../shared/constants/tracking';
import { ARTICLE_RECOMMENDATION_TITLE } from '../../components/Paragraphs/components/ParagraphsRenderer/constants';
import { PARAGRAPHS_FOR_FREE } from '../Article/constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../../common/assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import type { ArticleProps } from './typings';

export type ArticlePropsInner = ArticleProps &
  WithHeaderProps & {
    setHeaderData: (props: HeaderState) => void;
    resetHeaderData: () => void;
    locationPathname: string;
    screenReady: boolean;
  } & {
    hasSubscriptions: boolean;
    isCrawler: boolean;
  };

export { PUBLICATION_SWISS_INSURANCE } from '../../../../../shared/constants/publications';

const ArticlePage = ({
  article,
  locationPathname,
  screenReady,
  setHeaderData,
  resetHeaderData,
  hasSubscriptions,
  isCrawler,
}: ArticlePropsInner) => {
  const {
    gcid,
    subtypeValue = '',
    channel,
    commentStatus,
    preferredUri,
    socialMediaTitle,
    title,
    shortTitle,
    lead,
    __typename,
    restrictionStatus,
  } = article;

  const isNativeAdvertising = isNativeAdvertisingFunction(article);

  useImpressionTracking({
    trackingDetailImpression: article?.trackingDetailImpression,
    pathname: locationPathname,
    screenReady,
    isNativeAdvertising,
  });

  useEffect(() => {
    setHeaderData({
      articleData: {
        gcid,
        title,
        shortTitle,
        lead,
        subtypeValue,
        channel,
        commentStatus,
        preferredUri,
        socialMediaTitle,
        restrictionStatus,
      },
      contentType: __typename,
    });
    return () => {
      resetHeaderData();
    };
  }, [
    __typename,
    gcid,
    channel,
    commentStatus,
    preferredUri,
    resetHeaderData,
    setHeaderData,
    socialMediaTitle,
    subtypeValue,
    title,
    shortTitle,
    lead,
    restrictionStatus,
  ]);

  const getIsReferrerWhitelisted = () => {
    let result: RegExpMatchArray;
    try {
      result = document.referrer.match(
        /(smd.ch|pro.swissdox.ch|essentials.swissdox.ch)/,
      );
    } catch (e) {
      // catch on ssr only
    }
    return result;
  };

  const isRestrictedArticle =
    __RINGIER_CONNECT_ENABLED__ &&
    [RESTRICTION_STATUS_PAID, RESTRICTION_STATUS_REGISTERED].includes(
      article?.restrictionStatus,
    );
  const shouldHideContent =
    __RINGIER_CONNECT_ENABLED__ &&
    !hasSubscriptions &&
    !getIsReferrerWhitelisted() &&
    isRestrictedArticle;

  const freeContentBody =
    (shouldHideContent &&
      !isCrawler &&
      lodashSlice(article.body, 0, PARAGRAPHS_FOR_FREE)) ||
    article.body;

  const enrichedArticleBody = isNativeAdvertising
    ? freeContentBody
    : enrichArticleBodyWithADs({ pageBody: freeContentBody });

  return (
    <div className={`article-detail ${styles.Wrapper}`}>
      <div className={styles.ArticleInner}>
        <EditButtons
          editContentUri={article.editContentUri}
          editRelationUri={article.editRelationUri}
          cloneContentUri={article.cloneContentUri}
        />

        {!isNativeAdvertising && (
          <div className="ad-wrapper ad-wrapper-mobile header-apn-zone">
            <div className={grid.Container}>
              <AppNexus slot={MMR_1} deviceType="mobile" />
            </div>
          </div>
        )}
        <ArticleHeader article={article} />

        <div
          className={classNames(
            styles.BodyWrapper,
            TRACKING_CLASS_ARTICLE_BODY,
          )}
        >
          <Paragraphs
            contentGcid={article.gcid}
            articleKeywords={article.keywords}
            pageBody={enrichedArticleBody}
            colStyle={classNames(grid.ColMd18, grid.ColXl17)}
            origin={article?.subtypeValue || article.__typename}
            showCap
            activeChannel={article?.channel?.title || ''}
            paragraphsForFree={
              (shouldHideContent && PARAGRAPHS_FOR_FREE) || null
            }
            isAdSuppressed={
              article?.channel?.suppressAds || isNativeAdvertising
            }
          />

          <AuthorsTeaser authors={article.authors?.edges || []} />

          {article.issue?.nid &&
            (!shouldHideContent || (isCrawler && shouldHideContent)) && (
              <div
                className={`${
                  (isRestrictedArticle && 'restricted-') || ''
                }article-magazin-issue`}
              >
                <MagazineIssueSection issueId={article.issue.nid} />
              </div>
            )}
          {shouldHideContent && !isCrawler && (
            <div
              className={classNames(styles.Paywall, {
                [styles.HiddenPaywall]: !shouldHideContent,
              })}
            >
              <div className={grid.Container}>
                <div className={grid.Row}>
                  <div className={grid.ColXl17}>
                    <div
                      id={PIANO_CONTAINER_INLINED}
                      className={PIANO_PLACEHOLDER_INLINED}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={sections.Section}>
          {article?.topics?.edges &&
            (!shouldHideContent || (isCrawler && shouldHideContent)) &&
            Array.isArray(article.topics.edges) &&
            article.topics.edges.length > 0 && (
              <div
                className={classNames(
                  `${
                    (isRestrictedArticle && 'restricted-') || ''
                  }article-alerts`,
                  grid.Container,
                  grid.HideForPrint,
                )}
              >
                <ArticleAlerts items={article.topics.edges} />
              </div>
            )}

          {false ? (
            <>
              <SmoothScroll anchorId={COMMENTS_ANCHOR_ID} offset={120} />
              {!isNativeAdvertising &&
                (!shouldHideContent || (isCrawler && shouldHideContent)) &&
                article.commentStatus &&
                article.commentStatus !== COMMENT_STATUS_HIDDEN && (
                  <div
                    className={`${
                      (isRestrictedArticle && 'restricted-') || ''
                    }article-comments`}
                  >
                    <Comments
                      articleId={article.id || ''}
                      gcid={gcid || ''}
                      commentStatus={article.commentStatus || ''}
                    />
                  </div>
                )}
            </>
          ) : null}
          {
            // TODO: check here if article belongs to a dossier; always false for now
            false && (
              <DossierFooter
                link="https://www.google.com"
                dossierName="Bitcoin: Eine Währung spaltet die Finanzwelt"
              />
            )
          }
          {article.canonicalUri && (
            <div className={classNames(grid.Container, grid.HideForPrint)}>
              <RecommendedContentSection
                article={{
                  canonicalUri: article.canonicalUri,
                  keywords: article.keywords,
                  preferredUri: article.preferredUri,
                  relatedArticles: article.relatedArticles,
                  gcid: article.gcid,
                }}
                title={ARTICLE_RECOMMENDATION_TITLE}
                limit={14}
                nativeAdvertisingConfig={[0, 1, 5, 9, 12, 13]}
                type={RECOMMENDATION_TYPE.NATGOAL}
                operation={RECOMMENDATION_OPERATION.WITH_RELATED_CONTENT}
                hasRelatedContentField={false}
                skipInArticleRecommendations={2}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getRootSchemaRestricted = ({ article, hasSubscriptions, isCrawler }) => {
  if (__RINGIER_CONNECT_ENABLED__) {
    return {};
  }

  let shouldHideContent =
    !hasSubscriptions &&
    [RESTRICTION_STATUS_PAID].includes(article?.restrictionStatus);

  if (isCrawler) {
    shouldHideContent = false;
  }

  const freeContentBody =
    (shouldHideContent && lodashSlice(article.body, 0, PARAGRAPHS_FOR_FREE)) ||
    article.body;

  const jsonLd: { isAccessibleForFree?: boolean; hasPart?: Object[] } = {
    isAccessibleForFree: true,
  };

  if ([RESTRICTION_STATUS_PAID].includes(article?.restrictionStatus)) {
    jsonLd.isAccessibleForFree = false;
    jsonLd.hasPart = [];
    for (let index = 1; index < freeContentBody.length; index++) {
      if (index >= PARAGRAPHS_FOR_FREE + 1) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: false,
          cssSelector: `.restricted-section-${index}`,
        });
      } else {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: true,
          cssSelector: `.section-${index}`,
        });
      }
    }

    if (!shouldHideContent) {
      if (article.issue?.nid) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: false,
          cssSelector: '.restricted-article-magazin-issue',
        });
      }

      if (
        Array.isArray(article?.topics?.edges) &&
        article.topics.edges.length > 0
      ) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: false,
          cssSelector: '.restricted-article-alerts',
        });
      }
      if (
        article?.commentStatus &&
        article.commentStatus !== COMMENT_STATUS_HIDDEN
      ) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: 'False',
          cssSelector: '.restricted-article-comments',
        });
      }
    }
  }

  return jsonLd;
};

const mapDispatchToProps = {
  setHeaderData,
  resetHeaderData,
};

const mapStateToProps = (state) => ({
  locationPathname:
    locationStateSelector(state).locationBeforeTransitions.pathname,
  screenReady: locationStateSelector(state).screenReady,
  hasSubscriptions: authStateSelector(state).hasSubscriptions,
  isCrawler: locationStateSelector(state)?.isCrawler || false,
});

export default compose(
  withHeaderProps,
  connect(mapStateToProps, mapDispatchToProps),
  withHelmet({
    getNode: (mapProps) => mapProps.article || null,
    rootSchemaType: ROOT_SCHEMA_TYPE_NEWS_ARTICLE,
    getRootSchemaRestricted,
  }),
)(ArticlePage);
