import React, { ReactElement } from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../../../common/components/Teaser/factory';
import { getAllAuthors } from '../../../../../../../../../shared/helpers/authors';
import Picture from '../../../../../../../../../common/components/Picture';
import { STYLE_1X1_140 } from '../../../../../../../../../shared/constants/images';
import {
  TEASER_LEAD_LENGTH,
  TEASER_LEAD_SUFFIX_TEXT,
  TEASER_S_OPINION_IDENTIFIER,
} from '../../../../constants';
import styles from './styles.legacy.css';
import {
  GetElementByProps,
  TeaserFactoryProps,
} from '../../../../../../../../../common/components/Teaser/typings';

type TeaserSOpinionPropsInner = TeaserFactoryProps &
  TeaserInterface & {
    authors?: Array<AuthorEdge> | null;
  };

export const getInnerContentByProps: GetElementByProps<
  TeaserSOpinionPropsInner
> = ({ authors }: TeaserSOpinionPropsInner): ReactElement => {
  const hasAuthors: boolean =
    authors?.edges &&
    Array.isArray(authors.edges) &&
    authors?.edges?.length > 0;

  if (!hasAuthors) {
    return null;
  }

  const imagePath: string | null =
    authors.edges[0]?.node?.imageParagraph?.image?.file?.relativeOriginPath ||
    null;

  return (
    <>
      {(imagePath && (
        <div className={styles.AuthorWrapper}>
          <div className={styles.AuthorContent}>
            <Picture
              alt={authors?.edges[0].node?.name}
              style_320={STYLE_1X1_140}
              className={styles.AuthorAvatar}
              relativeOrigin={imagePath}
              focalPointX={
                authors.edges[0]?.node?.imageParagraph?.image?.file.focalPointX
              }
              focalPointY={
                authors.edges[0]?.node?.imageParagraph?.image?.file.focalPointY
              }
            />
            <div className={styles.Authors}>
              {getAllAuthors({
                authors: authors.edges,
                isBold: true,
                language: 'fr',
              })}
            </div>
          </div>
        </div>
      )) ||
        null}
    </>
  );
};

const TeaserSOpinion = teaserFactory({
  innerContent: getInnerContentByProps,
  leadOptions: {
    truncateCount: TEASER_LEAD_LENGTH,
    suffixText: TEASER_LEAD_SUFFIX_TEXT,
  },
  styles: {
    OuterWrapper: styles.OuterWrapper,
    Wrapper: classNames(TEASER_S_OPINION_IDENTIFIER, styles.Wrapper),
    ContentWrapper: styles.ContentWrapper,
    Title: styles.Title,
    Lead: styles.Lead,
    ShortTitle: styles.ShortTitle,
  },
});

export default TeaserSOpinion;
