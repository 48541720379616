import React from 'react';
import classNames from 'classnames';
import CollapsableInfoBox from './components/CollapsableBox';
import {
  TRACKING_CLASS_INFO_BOX_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../shared/constants/tracking';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';

const InfoBoxParagraph = ({ infoBoxParagraph, origin }) => {
  if (
    !infoBoxParagraph.infoBox ||
    !infoBoxParagraph.infoBox.body ||
    infoBoxParagraph.infoBox.body.length < 1
  ) {
    return null;
  }

  return (
    <CollapsableInfoBox
      infoBoxParagraph={infoBoxParagraph}
      articleColStyle={classNames(
        grid.ColOffsetXs1,
        grid.ColXs22,
        grid.ColMd16,
        grid.ColXl15,
      )}
      origin={origin}
    />
  );
};

const InfoBoxParagraphWrapper = (props) => (
  <div
    className={classNames(
      TRACKING_CLASS_PARAGRAPH,
      TRACKING_CLASS_INFO_BOX_PARAGRAPH,
    )}
  >
    <InfoBoxParagraph {...props} />
  </div>
);

export default InfoBoxParagraphWrapper;
