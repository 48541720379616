/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { GET_KEYWORD_LISTING } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ params: { searchString = 'A' } }) => ({
    query: GET_KEYWORD_LISTING,
    variables: {
      searchString: searchString && searchString.toUpperCase(),
      publication: 'PME',
    },
    skip: !!(searchString && searchString.length > 1),
  }),
};
