import React, { ComponentType } from 'react';
import Logo from '../../../../../../../Logo';
import RefetchGqlDataLink from '../../../../../../../RefetchGqlDataLink';
import styles from './styles.legacy.css';

const MinistageListicleHeader: ComponentType = () => {
  return (
    <div className={styles.LinkWrapper}>
      <RefetchGqlDataLink path={'/'} className={styles.Logo}>
        <Logo />
      </RefetchGqlDataLink>
    </div>
  );
};

export default MinistageListicleHeader;
