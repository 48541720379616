import classNames from 'classnames';
import entityQueueParagraphFactory, {
  EntityQueueParagraphPropsInner,
} from '../../../../../../../common/components/Paragraphs/components/EntityQueueParagraph/factory';
import { ensureTeaserInterface } from '../../../Teaser/shared/helpers';
import TeaserGrid from '../../../TeaserGrid';
import { LANDING_PAGE_TYPE_HOME } from '../../../../../../../shared/constants/content';
import {
  TRACKING_CLASS_ENTITY_QUEUE_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../shared/constants/tracking';
import {
  GRID_LAYOUT_ENTITY_QUEUE_DEFAULT,
  GRID_LAYOUT_ENTITY_QUEUE_HOME,
} from '../../../TeaserGrid/gridConfigs/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

export const getGridLayoutByProps = ({
  isFirst,
  origin,
}: EntityQueueParagraphPropsInner) => {
  if (isFirst && origin === LANDING_PAGE_TYPE_HOME) {
    return GRID_LAYOUT_ENTITY_QUEUE_HOME;
  }

  return GRID_LAYOUT_ENTITY_QUEUE_DEFAULT;
};

const EntityQueueParagraph = entityQueueParagraphFactory({
  ensureTeaserInterface,
  TeaserGrid,
  gridConfigLayout: getGridLayoutByProps,
  trackingClass: classNames(
    TRACKING_CLASS_PARAGRAPH,
    TRACKING_CLASS_ENTITY_QUEUE_PARAGRAPH,
  ),
  styles: {
    InnerContainer: grid.Container,
    Title: styles.Title,
  },
});

export default EntityQueueParagraph;
