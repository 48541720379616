import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import authStateSelector from '../../../../../../../shared/selectors/authStateSelector';
import { setNavigationVisible } from '../../../../../../../shared/actions/navigation';
import IconUser from '../../../SVGIcon/components/User';
import IconUserActive from '../../../SVGIcon/components/UserActive';
import { Auth0 } from '../../../../../../../common/components/Auth0Provider';
import { AUTH0_LOGIN_CASE_GENERAL } from '../../../../../../../common/components/Auth0Provider/constants';
import { TYPE_NAVIGATION_MENU_USER } from '../../../Navigation/constants';
import styles from './styles.legacy.css';

const HeaderUserLogin = (): ReactElement => {
  const isAuthenticated = useSelector(
    (state) => authStateSelector(state).isAuthenticated,
  );
  const initialAuthRequest = useSelector(
    (state) => authStateSelector(state).initialAuthRequest,
  );

  const dispatch = useDispatch();
  const toggleNavigation = (type: string) => {
    dispatch(setNavigationVisible(type));
  };

  return (
    <li className={styles.NavItem}>
      {(initialAuthRequest &&
        (isAuthenticated ? (
          <button
            data-testid="header-user-logout"
            className={styles.UserButton}
            onClick={() => toggleNavigation(TYPE_NAVIGATION_MENU_USER)}
          >
            <IconUserActive />
          </button>
        ) : (
          <button
            data-testid="header-user-login"
            className={styles.UserButton}
            onClick={() => Auth0.login(AUTH0_LOGIN_CASE_GENERAL)}
            role="link"
            aria-label="login"
          >
            <IconUser />
          </button>
        ))) || (
        <div className={styles.UserButton}>
          <IconUser />
        </div>
      )}
    </li>
  );
};

export default HeaderUserLogin;
