/**
 * @file   Overview Page Header switch component
 * @author Karol Stępień <karol.stepien@dreamlab.pl>
 * @author Damian Bucki <damian.bucki@dreamlab.pl>
 * @date   2019-08-28
 *
 */

import Bilanz from './components/Bilanz';
import Default from './components/Default';
import createComponentSwitch from '../../../../shared/decorators/componentSwitch';
import {
  OVERVIEW_PAGE_HEADER_BILANZ,
  OVERVIEW_PAGE_HEADER_DEFAULT,
} from './constants';

const OverviewPageHeaderLayouts = {
  [OVERVIEW_PAGE_HEADER_BILANZ]: Bilanz,
  [OVERVIEW_PAGE_HEADER_DEFAULT]: Default,
};

export default createComponentSwitch(OverviewPageHeaderLayouts);
