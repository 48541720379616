/* istanbul ignore file */

import subscribeButtonFactory from '../../../../../common/components/SubscribeButton/factory';
import Icon from '../Icon';
import { Auth0 } from '../../../../../common/components/Auth0Provider';
import { displayErrorToast, displayInfoToast } from '../Toast';
import {
  AUTHORIZATION_ERROR_ID,
  AUTHORIZATION_ERROR_MESSAGE,
  AUTHORIZATION_INFO_ID,
  AUTHORIZATION_INFO_MESSAGE,
  AUTHORIZATION_LINK_TEXT,
  LIMIT_EXCEEDED_ERROR_ID,
  LIMIT_EXCEEDED_ERROR_LINK_PATH,
  LIMIT_EXCEEDED_ERROR_LINK_TEXT,
  LIMIT_EXCEEDED_ERROR_MESSAGE,
} from '../Toast/constants';
import styles from './styles.legacy.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [styles.BellAnimation];

export default __RINGIER_CONNECT_ENABLED__
  ? subscribeButtonFactory({
      styles: {
        SubscribeButtonWrapper: styles.SubscribeButtonWrapper,
        LightTheme: styles.LightTheme,
        Text: styles.Text,
        Icon: styles.Icon,
        Active: styles.Active,
        Animating: styles.Animating,
      },
      followText: 'Suivre',
      followingText: 'Suivi',
      Icon,
      ToastService: {
        displayDefaultErrorToast: () => displayErrorToast(),
        displayAuthenticationErrorToast: () =>
          displayErrorToast(
            AUTHORIZATION_ERROR_MESSAGE,
            {
              text: AUTHORIZATION_LINK_TEXT,
              onClick: Auth0.login,
            },
            AUTHORIZATION_ERROR_ID,
          ),
        displayLimitExceededToast: () =>
          displayErrorToast(
            LIMIT_EXCEEDED_ERROR_MESSAGE,
            {
              text: LIMIT_EXCEEDED_ERROR_LINK_TEXT,
              path: LIMIT_EXCEEDED_ERROR_LINK_PATH,
            },
            LIMIT_EXCEEDED_ERROR_ID,
          ),
        displayAuthenticationInfoToast: () =>
          displayInfoToast(
            AUTHORIZATION_INFO_MESSAGE,
            {
              text: AUTHORIZATION_LINK_TEXT,
              onClick: Auth0.login,
            },
            AUTHORIZATION_INFO_ID,
          ),
      },
    })
  : null;
