/* istanbul ignore file */

import articleRecommendationsFactory from '../../../../../../../common/components/Recommendations/components/ArticleRecommendations/factory';
import { ensureTeaserInterface } from '../../../Teaser/shared/helpers';
import TeaserGrid from '../../../TeaserGrid';
import { GRID_LAYOUT_RECOMMENDATIONS } from '../../../TeaserGrid/gridConfigs/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';

const ArticleRecommendations = articleRecommendationsFactory({
  ensureTeaserInterface,
  TeaserGrid,
  teaserGridLayout: GRID_LAYOUT_RECOMMENDATIONS,
  styles: {
    Container: sections.Container,
    Row: grid.Row,
    Title: styles.Title,
    TitleWrapper: styles.TitleWrapper,
    Wrapper: styles.Wrapper,
  },
});

export default ArticleRecommendations;
