/**
 * @file   router queries
 *
 */

import { gql } from '@apollo/client';
import { articleFragment } from 'Article/fragments';
import { authorFragment } from 'Author/fragments';
import { explainingArticleFragment } from 'ExplainingArticle/fragments';
import { imageGalleryArticleFragment } from 'ImageGalleryArticle/fragments';
import { keywordArticlesListFragment } from 'Keywords/screens/KeywordArticlesList/fragments';
import { landingPageFragment } from 'LandingPage/fragments';
import { nativeAdvertisingFragment } from 'NativeAdvertising/fragments';
import { pageScreenFragment } from 'PageScreen/fragments';
import { sponsorFragment } from 'Sponsor/fragments';
import { videoFragment } from 'Video/fragments';

export const ROUTER_ROUTE_BY_PATH_QUERY = gql`
  query RouteByPath(
    $path: String!
    $publication: PublicationEnum
    $additionalPublications: [PublicationEnum]
    $landingPageGridPageSize: Int!
    $landingPageGridOffset: Int!
    $keywordsPageSize: Int!
    $keywordsOffset: Int!
    $sponsorLimit: Int
    $sponsorSortBy: SortTypeEnum
    $sponsorSortOrder: SortOrderEnum
    $sponsorOffset: Int!
  ) {
    environment(
      publication: $publication
      additionalPublications: $additionalPublications
    ) {
      routeByPath(path: $path) {
        canonical
        preferred
        statusCode
        object {
          ...ArticleFragment
          ...ExplainingArticleFragment
          ...ImageGalleryArticleFragment
          ...LandingPageFragment
          ...KeywordArticlesListFragment
          ...SponsorFragment
          ...NativeAdvertisingFragment
          ...PageScreenFragment
          ...VideoFragment
          ...AuthorFragment
        }
      }
    }
  }

  ${articleFragment}
  ${explainingArticleFragment}
  ${imageGalleryArticleFragment}
  ${keywordArticlesListFragment}
  ${sponsorFragment}
  ${nativeAdvertisingFragment}
  ${pageScreenFragment}
  ${landingPageFragment}
  ${videoFragment}
  ${authorFragment}
`;
