/* istanbul ignore file */
import recommendedContentSectionFactory from '../../../../../../../common/components/Recommendations/components/RecommendedContentSection/factory';
import { ensureTeaserInterface } from '../../../../components/Teaser/shared/helpers';
import RelatedContent from '../../../../../App/components/RelatedContent';
import { PUBLICATION_GROUP_PME } from '../../../../../../../shared/constants/publications';
import { GRID_LAYOUT_TEASER_4X4 } from '../../../../components/TeaserGrid/gridConfigs/constants';

const RecommendedContentSection = recommendedContentSectionFactory({
  RelatedContent,
  ensureTeaserInterface,
  publication: PUBLICATION_GROUP_PME,
  gridLayout: GRID_LAYOUT_TEASER_4X4,
  hasTitleContainer: true,
});

export default RecommendedContentSection;
