import React from 'react';

const SocialFacebookAlt = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    aria-hidden={true}
  >
    <g>
      <path d="M17.52 10.96V9.12c0-.8.16-1.2 1.44-1.2h1.6V4.88h-2.64c-3.28 0-4.32 1.52-4.32 4.08v2h-2.16V14h2.16v9.12h3.92V14h2.72l.4-3.04h-3.12z" />
    </g>
  </svg>
);

export default SocialFacebookAlt;
