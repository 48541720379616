import React, { ReactElement } from 'react';
import BodyClassName from 'react-body-classname';
import classNames from 'classnames';
import { generateShortTitle } from '../../../../../../shared/helpers/createLabels';
import Breadcrumbs from '../../../../../../screens/App/components/Breadcrumbs';
import TimeToRead from '../../../../../../screens/App/components/TimeToRead';
import Tooltip from '../../../../../../screens/App/components/Tooltip';
import UtilityBar from '../../../../../../screens/App/components/UtilityBar';
import UtilityOverlay from '../../../../../../screens/App/components/UtilityBar/components/UtilityOverlay';
import SeatChangeImage from '../ArticleHeader/components/SeatChangeImage';

import {
  ADVERTISING_TYPE_ADVERTORIAL,
  ADVERTISING_TYPE_BRANDREPORT,
  ADVERTISING_TYPE_NATIVE_ARTICLE,
  ARTICLE_TYPE_SEATCHANGE,
  ANCHOR_TITLE,
  ANCHOR_SHORT_TITLE,
} from '../../../../../../../shared/constants/content';
import {
  UTILITYBAR_CONFIG,
  UTILITYBAR_OVERLAY_CONFIG,
} from '../../../../../../screens/App/components/UtilityBar/constants';
import styles from './styles.legacy.css';
import { ArticleTitleProps } from './typings';

type ArticleTitlePropsInner = ArticleTitleProps;

const tooltipContent =
  'Ce contenu a été créé par ou en collaboration avec un partenaire publicitaire.';

const tooltipLink = {
  path: '/partenariats',
  text: 'En savoir plus',
};

const ArticleTitle = ({
  article,
  articleColStyle,
}: ArticleTitlePropsInner): ReactElement => {
  const relatedPeople: PersonConnection =
    article?.subtypeValue === ARTICLE_TYPE_SEATCHANGE &&
    Array.isArray(article.relatedPersons?.edges) &&
    article.relatedPersons?.edges.length > 0
      ? article.relatedPersons
      : {
          edges: [{ node: { teaserImage: article.teaserImage } }],
          count: 1,
          totalCount: 1,
          pageInfo: { hasNextPage: null, hasPreviousPage: null },
        };

  const isTooltipVisible =
    article?.subtypeValue === ADVERTISING_TYPE_BRANDREPORT ||
    article?.subtypeValue === ADVERTISING_TYPE_ADVERTORIAL ||
    article?.subtypeValue === ADVERTISING_TYPE_NATIVE_ARTICLE;

  return (
    <div
      className={classNames(
        styles.ArticleTitle,
        'article-title',
        articleColStyle,
      )}
      data-testid="articletitle-wrapper"
    >
      <div>
        <Breadcrumbs
          pageUrl={article.preferredUri}
          items={article.activeMenuTrail}
        />
        <div id={ANCHOR_SHORT_TITLE} className={styles.ShortTitleWrapper}>
          <span
            data-testid="articletitle-shorttitle"
            className={classNames(styles.ShortTitle, {
              [styles.ShortTitleNA]:
                article.subtypeValue === ADVERTISING_TYPE_NATIVE_ARTICLE,
              [styles.ShortTitleAdvertorial]:
                article.subtypeValue === ADVERTISING_TYPE_ADVERTORIAL,
            })}
          >
            {article?.shortTitle ||
              generateShortTitle({
                subtypeValue: article.subtypeValue,
                channelTitle: article.channel?.title,
              })}
          </span>
          {isTooltipVisible && (
            <Tooltip
              content={tooltipContent}
              link={tooltipLink}
              origin={ADVERTISING_TYPE_BRANDREPORT}
            ></Tooltip>
          )}
        </div>

        {article?.subtypeValue === ARTICLE_TYPE_SEATCHANGE && (
          <SeatChangeImage relatedPersons={relatedPeople} />
        )}
        {article.title && (
          <h1 id={ANCHOR_TITLE} data-testid="articletitle-title-wrapper">
            <div
              className={classNames(styles.Title, {
                [styles.HasTimeToRead]: !!article.time2read,
              })}
            >
              {article.title}
            </div>
          </h1>
        )}
        {article.time2read && (
          <TimeToRead
            seconds={article.time2read}
            addClass={classNames(
              'time-to-read-in-article-head',
              styles.TimeToRead,
            )}
          />
        )}
        <BodyClassName className={styles.ArticleBody}>
          <div
            className={classNames(
              'utility-bar-wrapper',
              styles.UtilityBarWrapper,
            )}
          >
            <UtilityBar enabledUtilities={UTILITYBAR_CONFIG}>
              {({ isOverlayVisible, toggleOverlayVisible }) => (
                <UtilityOverlay
                  overlayTitle="Partager cet article"
                  isOverlayVisible={isOverlayVisible}
                  toggleOverlayVisible={toggleOverlayVisible}
                  enabledUtilities={UTILITYBAR_OVERLAY_CONFIG}
                />
              )}
            </UtilityBar>
          </div>
        </BodyClassName>
      </div>
    </div>
  );
};

export default ArticleTitle;
