/* istanbul ignore file */

import React from 'react';
import teaserFactory, {
  TeaserFactoryProps,
} from '../../../../../../../common/components/Teaser/factory';
import { getTitleBadgeByProps } from '../../shared/helpers';
import { CONTENT_SOURCE_MOST_READ } from '../../../../../../../shared/constants/content';
import styles from './styles.legacy.css';

const getBadgeByProps = ({ index, contentBoxType }: TeaserFactoryProps) => {
  if (contentBoxType === CONTENT_SOURCE_MOST_READ && index) {
    return (
      <div className={styles.NumberWrapper}>
        <span className={styles.Number}>{index}</span>
      </div>
    );
  }
  return;
};

const TeaserText = teaserFactory({
  isShortTitleHidden: true,
  badge: getBadgeByProps,
  titleBadge: getTitleBadgeByProps(),
  styles: {
    Wrapper: styles.Wrapper,
    ContentWrapper: styles.ContentWrapper,
    Title: styles.TeaserTitle,
    TitleTickerBox: styles.TitleTickerBox,
  },
});

export default TeaserText;
