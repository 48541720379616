import { TYPE_TEASER } from '../../../../../../../common/components/TeaserGrid/gridConfigs/constants';
import { TEASER_LAYOUT_RANKING_LIST } from '../../../../../../../shared/constants/teaser';

export const teaserRankingList = {
  gridGroups: [
    {
      config: {
        hasContainer: false,
      },
      items: [
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_RANKING_LIST,
        },
      ],
    },
  ],
};
