import classNames from 'classnames';
import teaserFactory from '../../../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../../../common/components/Teaser/helpers';
import {
  getBadgeByProps,
  getFormattedPublicationDateByProps,
  getIconByProps,
  getIsBottomLineVisibleByProps,
} from '../../../../shared/helpers';
import {
  ADVERTISING_TYPE_ADVERTORIAL,
  ADVERTISING_TYPE_LONGFORM,
  ADVERTISING_TYPE_NATIVE_ARTICLE,
} from '../../../../../../../../../shared/constants/content';
import {
  STYLE_16X9_560,
  STYLE_16X9_700,
  STYLE_1X1_280,
  STYLE_3X2_440,
} from '../../../../../../../../../shared/constants/images';
import {
  TEASER_HERO_DEFAULT_IDENTIFIER,
  TEASER_IMAGE_IDENTIFIER,
  TEASER_LEAD_LENGTH,
  TEASER_LEAD_SUFFIX_TEXT,
} from '../../../../constants';
import styles from './styles.legacy.css';

const getStylesByProps = ({ subtypeValue }) => ({
  Wrapper: classNames(TEASER_HERO_DEFAULT_IDENTIFIER, styles.Wrapper),
  ContentWrapper: styles.Right,
  ImageWrapper: styles.Left,
  Image: classNames(styles.Image, TEASER_IMAGE_IDENTIFIER),
  Title: styles.TeaserTitleWrapper,
  TitleInner: styles.Title,
  Lead: styles.Lead,
  BottomLine: styles.BottomLine,
  getIsBottomLineVisibleByProps,
  ShortTitle: classNames(styles.ShortTitle, {
    [styles.ShortTitleNA]:
      subtypeValue === ADVERTISING_TYPE_NATIVE_ARTICLE ||
      subtypeValue === ADVERTISING_TYPE_LONGFORM,
    [styles.ShortTitleAdvertorial]:
      subtypeValue === ADVERTISING_TYPE_ADVERTORIAL,
  }),
});

const TeaserHeroDefault = teaserFactory({
  badge: getBadgeByProps(styles.Badge),
  icon: getIconByProps(styles.Icon),
  teaserImageStyles: {
    style_320: STYLE_16X9_560,
    style_760: STYLE_1X1_280,
    style_960: STYLE_3X2_440,
    style_1680: STYLE_16X9_700,
  },
  isIconPositionOnImage: true,
  trackingTeaserHandler: withTeaserTrackingHandler,
  formattedPublicationDate: getFormattedPublicationDateByProps,
  isPublicationDateVisible: false,
  isAuthorVisible: getIsBottomLineVisibleByProps,
  leadOptions: {
    truncateCount: TEASER_LEAD_LENGTH,
    suffixText: TEASER_LEAD_SUFFIX_TEXT,
  },
  styles: getStylesByProps,
});

export default TeaserHeroDefault;
