/* istanbul ignore file */

import factory from '../../../TeaserML/components/TeaserMLDefault/factory';
import { TEASER_M_DEFAULT_IDENTIFIER } from '../../../../constants';

import {
  STYLE_16X9_440,
  STYLE_16X9_560,
  STYLE_3X2_280,
} from '../../../../../../../../../shared/constants/images';
import styles from '../../styles.legacy.css';

export default factory({
  teaserImageStyles: {
    style_320: STYLE_16X9_560,
    style_760: STYLE_3X2_280,
    style_1680: STYLE_16X9_440,
  },
  teaserIdentifier: TEASER_M_DEFAULT_IDENTIFIER,
  styles: { MinimumHeight: styles.MinimumHeight },
});
