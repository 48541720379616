import {
  TEASER_LAYOUT_L as TEASER_L,
  TEASER_LAYOUT_M as TEASER_M,
} from '../../../../../shared/constants/teaser';

// IMAGE
export const TEASER_IMAGE_IDENTIFIER = 'teaser-image';
export const TEASER_PLACEHOLDER_PATH =
  '/media/field_image/2017-10/placeholder.gif';

// CONFIG
export const TEASER_LEAD_LENGTH = 147;
export const TEASER_XS_LEAD_LENGTH = 120;
export const TEASER_LEAD_LENGTH_SEATCHANGE = 197;
export const TEASER_LEAD_SUFFIX_TEXT = ' …';
export const TEASER_TITLE_LENGTH = 120;
export const TEASER_SMALL_TEXT_TITLE_LENGTH = 55;

// CTA
export const TEASER_CTA_SPECIAL = 'zum Special';
export const TEASER_CTA_CHANNEL = 'A découvrir';
export const TEASER_CTA_DOSSIER = 'zum Dossier';

// Long Read label
export const ARTICLE_TYPE_LONG_READ_LABEL = 'Long format';

// TEASER HERO
export const TEASER_HERO_DEFAULT_IDENTIFIER = 'teaser-hero-default';
export const TEASER_HERO_OPINION_IDENTIFIER = 'teaser-hero-opinion';
export const TEASER_HERO_BRANDREPORT_IDENTIFIER = 'teaser-hero-brand-report';
export const TEASER_LAYOUT_HERO = 'teaser/layout-hero';

// TEASER L
export const TEASER_L_DEFAULT_IDENTIFIER = 'teaser-l-default';
export const TEASER_L_OPINION_IDENTIFIER = 'teaser-l-opinion';
export const TEASER_L_BRANDREPORT_IDENTIFIER = 'teaser-l-brand-report';
export const TEASER_LAYOUT_L = TEASER_L;

// TEASER M
export const TEASER_M_DEFAULT_IDENTIFIER = 'teaser-m-default';
export const TEASER_M_OPINION_IDENTIFIER = 'teaser-m-opinion';
export const TEASER_M_BRANDREPORT_IDENTIFIER = 'teaser-m-brand-report';
export const TEASER_LAYOUT_M = TEASER_M;

// TEASER S
export const TEASER_S_DEFAULT_IDENTIFIER = 'teaser-s-default';
export const TEASER_S_OPINION_IDENTIFIER = 'teaser-s-opinion';
export const TEASER_S_BRANDREPORT_IDENTIFIER = 'teaser-s-brand-report';
export const TEASER_LAYOUT_S = 'teaser/layout-s';

//TEASER XS
export const TEASER_XS_DEFAULT_IDENTIFIER = 'teaser-xs-default';
export const TEASER_XS_OPINION_IDENTIFIER = 'teaser-xs-opinion';
export const TEASER_XS_BRANDREPORT_IDENTIFIER = 'teaser-xs-brand-report';
export const TEASER_LAYOUT_XS = 'teaser/layout-xs';

// TEASER XXS
export const TEASER_XXS_DEFAULT_IDENTIFIER = 'teaser-xxs-default';
export const TEASER_LAYOUT_XXS = 'teaser/layout-xxs';

// OTHER TEASERS
export const TEASER_RANKING_LIST_IDENTIFIER = 'teaser-ranking-list';
export const TEASER_SPONSOR_IDENTIFIER = 'teaser-sponsor-company';
export const TEASER_LAYOUT_VIDEO = 'teaser/layout-video';
export const TEASER_LAYOUT_TEAM_MEMBERS = 'teaser/layout-team-members';
export const TEASER_LAYOUT_TIMELINE = 'teaser/layout-timeline';
export const TEASER_LAYOUT_SPONSOR = 'teaser/layout-sponsor';
export const TEASER_LAYOUT_ORGANIZATION = 'teaser/layout-organization';
export const TEASER_LAYOUT_RANKING = 'teaser/layout-ranking';
export const TEASER_LAYOUT_RANKING_LIST = 'teaser/layout-ranking-list';
export const TEASER_LAYOUT_RECOMMENDATIONS = 'teaser/layout-recommendations';
export const TEASER_LAYOUT_IS_SPECIAL = 'special';
export const TEASER_LAYOUT_TEXT = 'teaser/layout-text';
export const TEASER_LAYOUT_CHANNEL = 'teaser/layout-channel';
export const TEASER_LAYOUT_SPECIAL = 'teaser/layout-special';
export const TEASER_LAYOUT_LANDINGPAGE = 'teaser/layout-landingpage';
export const TEASER_LAYOUT_BOOKMARKS = 'teaser/layout-bookmark';
export const TEASER_BOOKMARK_IDENTIFIER = 'teaser-bookmark';

//remove after adjusting hz-badge
export const TEASER_MAIN_IDENTIFIER = 'teaser-main';
export const TEASER_SECONDARY_IDENTIFIER = 'teaser-secondary';
