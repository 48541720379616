import { ReactElement } from 'react';
// @ts-ignore
import { ReactComponent as StatusPagePME404 } from '../../../../../common/components/SVGIcon/assets/statusPage/hz_404.svg';
// @ts-ignore
import { ReactComponent as StatusPagePME451 } from '../../../../../common/components/SVGIcon/assets/statusPage/hz_451.svg';
// @ts-ignore
import { ReactComponent as StatusPagePME500 } from '../../../../../common/components/SVGIcon/assets/statusPage/hz_500.svg';
import { ReactComponent as LogoBlack } from './components/logo-black.svg';
import { ReactComponent as LogoWhite } from './components/logo-white.svg';
import { SVG_ICONS_DEFAULT_CONFIG } from '../../../../../shared/constants/svgIcons';
import { LogoSvgTypes } from '../Logo/typings';

const SVG_LOGOS: Record<LogoSvgTypes, ReactElement> = {
  'logo/white': LogoWhite,
  'logo/black': LogoBlack,
};

export const SVG_ICONS_TYPE_PME_404 = 'svg-icons/type/hz-404';
export const SVG_ICONS_TYPE_PME_451 = 'svg-icons/type/hz-451';
export const SVG_ICONS_TYPE_PME_500 = 'svg-icons/type/hz-500';

export const SVG_ICONS_CONFIG = {
  ...SVG_LOGOS,
  ...SVG_ICONS_DEFAULT_CONFIG,
  [SVG_ICONS_TYPE_PME_404]: StatusPagePME404,
  [SVG_ICONS_TYPE_PME_451]: StatusPagePME451,
  [SVG_ICONS_TYPE_PME_500]: StatusPagePME500,
};
