import React from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../../../../../common/components/Link';
import Icon from '../../../../../../../Icon';
import styles from './styles.legacy.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  styles.Facebook,
  styles.Linkedin,
  styles.Twitter,
  styles.Xing,
  styles.Instagram,
];

const MinistageSocialMediaIcon = ({ type, link }) => {
  if (!type || !link) {
    return null;
  }

  return (
    <Link path={link} className={styles.Link} target="_blank">
      <div className={classNames(styles.Content, styles[type])}>
        <Icon
          type={type === 'Facebook' ? `Icon${type}F` : `Icon${type}`}
          addClass={styles.Icon}
        />
      </div>
    </Link>
  );
};

export default MinistageSocialMediaIcon;
