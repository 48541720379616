import { gql } from '@apollo/client';

export const searchResultFragment = gql`
  fragment SearchResultFragment on SearchableUnionConnection {
    edges {
      node {
        ... on LandingPage {
          id
          title
          lead
          shortTitle
          preferredUri
          sponsor {
            id
          }
          channel {
            id
            channelType
          }
          teaserImage {
            id
            image {
              id
              file(style: "large") {
                id
                alt
                relativeOriginPath
                focalPointX
                focalPointY
              }
            }
          }
          useAutoHyphens
        }
        ... on NativeAdvertising {
          id
          gcid
          title
          lead
          shortTitle
          changeDate
          publicationDate
          trackingTeaserImpression
          trackingTeaserClick
          preferredUri
          subtypeValue: advertisingTypeValue
          advertisingTypeLabel
          authors(limit: 10) {
            edges {
              node {
                id
                name
                imageParagraph {
                  id
                  image {
                    id
                    file(style: "large") {
                      id
                      alt
                      relativeOriginPath
                      focalPointX
                      focalPointY
                    }
                  }
                }
              }
            }
          }
          link {
            path
            label
          }
          channel {
            id
            title
            settings {
              hierarchy {
                count
                edges {
                  node {
                    id
                    title
                    tid
                  }
                }
              }
            }
          }
          sponsor {
            id
            title
            teaserImage {
              id
              title
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
          }
          hasVideo
          teaserImage {
            id
            caption
            image {
              id
              file(style: "large") {
                id
                alt
                relativeOriginPath
                focalPointX
                focalPointY
              }
            }
          }
          useAutoHyphens
        }
        ... on Article {
          preferredUri
          subtypeValue: articleType
          id
          title
          restrictionStatus
          lead
          shortTitle
          changeDate
          publicationDate
          channel {
            id
            title
          }
          hasVideo
          authors(limit: 10) {
            edges {
              node {
                id
                name
                imageParagraph {
                  id
                  image {
                    id
                    file(style: "large") {
                      id
                      alt
                      relativeOriginPath
                      focalPointX
                      focalPointY
                    }
                  }
                }
              }
            }
          }
          teaserImage {
            id
            image {
              id
              file(style: "large", calculateDimensions: true) {
                id
                alt
                relativeOriginPath
                width
                height
                focalPointX
                focalPointY
              }
            }
          }
          relatedPersons {
            edges {
              node {
                teaserImage {
                  id
                  image {
                    id
                    file(style: "large") {
                      id
                      alt
                      relativeOriginPath
                      focalPointX
                      focalPointY
                    }
                  }
                }
              }
            }
          }
          useAutoHyphens
        }
      }
    }
  }
`;
