import TeaserGrid from '../TeaserGrid';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../Pager';
import bookmarkListFactory from '../../../../../common/components/BookmarkList/factory';
import { TEASER_LAYOUT_BOOKMARKS } from '../../../../../shared/constants/teaser';

const BookmarkList = bookmarkListFactory({
  styles: {
    BookmarkListWrapper: '',
  },
  TeaserGrid,
  teaserType: TEASER_LAYOUT_BOOKMARKS,
  Pager,
  pagerType: PAGER_TYPE_PAGE_LOADER,
});

export default BookmarkList;
