import { gql } from '@apollo/client';

export const entityQueueParagraphFragment = gql`
  fragment EntityQueueParagraphFragment on EntityQueueParagraph {
    id
    anchorId
    title
    landingPage {
      title
      preferredUri
    }
    entityQueue {
      items {
        edges {
          node {
            ... on ContentBox {
              id
              title
              contentSourceValue
              linkLabel
              termReference {
                ... on Channel {
                  id
                  tid
                  title
                  link
                }
              }
              useNativeAdvertising
              items {
                edges {
                  node {
                    id
                    ... on Article {
                      id
                      title
                      lead
                      shortTitle
                      publicationDate
                      preferredUri
                      authors(limit: 2) {
                        edges {
                          node {
                            id
                            name
                            imageParagraph {
                              id
                              image {
                                id
                                file(style: "large") {
                                  id
                                  alt
                                  relativeOriginPath
                                  focalPointX
                                  focalPointY
                                }
                              }
                            }
                          }
                        }
                      }
                      teaserImage {
                        id
                        image {
                          id
                          file(style: "large", calculateDimensions: true) {
                            id
                            alt
                            width
                            height
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ... on LandingPage {
                      id
                      nid
                      title
                      preferredUri
                      teaserImage {
                        id
                        image {
                          id
                          file(style: "large") {
                            id
                            alt
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ... on Page {
                      id
                      nid
                      title
                      preferredUri
                      teaserImage {
                        id
                        image {
                          id
                          file(style: "large") {
                            id
                            alt
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ... on NativeAdvertising {
                      id
                      title
                      lead
                      hasVideo
                      subtypeValue: advertisingTypeValue
                      advertisingTypeLabel
                      shortTitle
                      lead
                      changeDate
                      publicationDate
                      showUpdated
                      restrictionStatus
                      preferredUri
                      link {
                        path
                        label
                      }
                      authors(limit: 2) {
                        edges {
                          node {
                            id
                            name
                            imageParagraph {
                              id
                              image {
                                id
                                file(style: "large") {
                                  id
                                  alt
                                  relativeOriginPath
                                  focalPointX
                                  focalPointY
                                }
                              }
                            }
                          }
                        }
                      }
                      teaserImage {
                        id
                        image {
                          id
                          file(style: "large", calculateDimensions: true) {
                            id
                            alt
                            width
                            height
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ... on ImageGallery {
                      id
                      title
                      lead
                      shortTitle
                      preferredUri
                      authors(limit: 10) {
                        edges {
                          node {
                            id
                            name
                            imageParagraph {
                              id
                              image {
                                id
                                file(style: "large") {
                                  id
                                  alt
                                  relativeOriginPath
                                  focalPointX
                                  focalPointY
                                }
                              }
                            }
                          }
                        }
                      }
                      teaserImage {
                        id
                        caption
                        image {
                          id
                          file(style: "large", calculateDimensions: true) {
                            id
                            alt
                            relativeOriginPath
                            width
                            height
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ... on Video {
                      id
                      title
                      lead
                      shortTitle
                      preferredUri
                      restrictionStatus
                      channel {
                        id
                        title
                      }
                      teaserImage {
                        id
                        image {
                          id
                          file(style: "large", calculateDimensions: true) {
                            id
                            alt
                            relativeOriginPath
                            width
                            height
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ... on Teaser {
                      link {
                        path
                      }
                      trackingEnabled
                      trackingTeaserImpression
                      trackingTeaserClick
                      title
                      shortTitle
                      lead
                      channel {
                        id
                        title
                        sponsors {
                          edges {
                            node {
                              id
                              title
                            }
                          }
                        }
                        settings {
                          mainChannel {
                            id
                            title
                          }
                          hierarchy {
                            count
                            edges {
                              node {
                                id
                                title
                                tid
                              }
                            }
                          }
                        }
                      }
                      teaserImage {
                        caption
                        image {
                          id
                          file(style: "large") {
                            id
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                  }
                }
              }
            }
            ... on LandingPage {
              id
              title
              lead
              shortTitle
              preferredUri
              useNativeAdvertising
              channel {
                id
                title
                channelType
              }
              teaserImage {
                id
                image {
                  id
                  file(style: "large") {
                    id
                    alt
                    relativeOriginPath
                    focalPointX
                    focalPointY
                  }
                }
              }
              useAutoHyphens
            }
            ... on Dossier {
              id
              preferredUri
              title
              teaserImage {
                id
                image {
                  id
                  file(style: "large", calculateDimensions: true) {
                    id
                    alt
                    relativeOriginPath
                    width
                    height
                    focalPointX
                    focalPointY
                  }
                }
              }
              useAutoHyphens
            }
            ... on ImageGallery {
              id
              title
              lead
              shortTitle
              preferredUri
              authors(limit: 10) {
                edges {
                  node {
                    id
                    name
                    imageParagraph {
                      id
                      image {
                        id
                        file(style: "large") {
                          id
                          alt
                          relativeOriginPath
                          focalPointX
                          focalPointY
                        }
                      }
                    }
                  }
                }
              }
              teaserImage {
                id
                caption
                image {
                  id
                  file(style: "large", calculateDimensions: true) {
                    id
                    alt
                    relativeOriginPath
                    width
                    height
                    focalPointX
                    focalPointY
                  }
                }
              }
              useAutoHyphens
            }
            ... on NativeAdvertising {
              id
              gcid
              title
              lead
              shortTitle
              changeDate
              trackingTeaserImpression
              trackingTeaserClick
              publication
              publicationDate
              preferredUri
              subtypeValue: advertisingTypeValue
              advertisingTypeLabel
              authors(limit: 10) {
                edges {
                  node {
                    id
                    name
                    imageParagraph {
                      id
                      image {
                        id
                        file(style: "large") {
                          id
                          alt
                          relativeOriginPath
                          focalPointX
                          focalPointY
                        }
                      }
                    }
                  }
                }
              }
              channel {
                id
                title
                settings {
                  hierarchy {
                    count
                    edges {
                      node {
                        id
                        title
                        tid
                      }
                    }
                  }
                }
              }
              link {
                path
                label
              }
              sponsor {
                id
                title
                preferredUri
                teaserImage {
                  id
                  title
                  image {
                    id
                    file(style: "large") {
                      id
                      alt
                      relativeOriginPath
                      focalPointX
                      focalPointY
                    }
                  }
                }
              }
              hasVideo
              teaserImage {
                id
                caption
                image {
                  id
                  file(style: "large", calculateDimensions: true) {
                    id
                    alt
                    relativeOriginPath
                    width
                    height
                    focalPointX
                    focalPointY
                  }
                }
              }
              useAutoHyphens
            }
            ... on Teaser {
              link {
                path
              }
              trackingEnabled
              trackingTeaserImpression
              trackingTeaserClick
              title
              shortTitle
              lead
              channel {
                id
                title
                sponsors {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
                settings {
                  mainChannel {
                    id
                    title
                  }
                  hierarchy {
                    count
                    edges {
                      node {
                        id
                        title
                        tid
                      }
                    }
                  }
                }
              }
              teaserImage {
                caption
                image {
                  id
                  file(style: "large") {
                    id
                    relativeOriginPath
                    focalPointX
                    focalPointY
                  }
                }
              }
              useAutoHyphens
            }
            ... on Article {
              id
              title
              lead
              restrictionStatus
              shortTitle
              changeDate
              preferredUri
              publication
              publicationDate
              subtypeValue: articleType
              useNativeAdvertising
              authors(limit: 10) {
                edges {
                  node {
                    id
                    name
                    imageParagraph {
                      id
                      image {
                        id
                        file(style: "large") {
                          id
                          alt
                          relativeOriginPath
                          focalPointX
                          focalPointY
                        }
                      }
                    }
                  }
                }
              }
              relatedPersons {
                edges {
                  node {
                    teaserImage {
                      id
                      image {
                        id
                        file(style: "large") {
                          id
                          alt
                          relativeOriginPath
                          focalPointX
                          focalPointY
                        }
                      }
                    }
                  }
                }
              }
              channel {
                id
                title
              }
              hasVideo
              teaserImage {
                id
                caption
                image {
                  id
                  file(style: "large") {
                    id
                    alt
                    relativeOriginPath
                    focalPointX
                    focalPointY
                  }
                }
              }
              useAutoHyphens
            }
          }
        }
      }
    }
  }
`;
