import React, { ReactElement } from 'react';
import { compose } from 'recompose';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import Link from '../../../../../../../common/components/Link';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Helmet from '../../../../components/Helmet';
import SubscribeButton from '../../../../components/SubscribeButton';
import StatusPage from './../../../StatusPage';
import SearchResult from './components/SearchResult';
import { getAlertItemTypeByTypename } from '../../../../../../../common/components/SubscribeButton/helper';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../../../shared/constants/structuredData';
import {
  ROUTE_KEYWORDS,
  SITE_TITLE,
} from '../../../../../../screens/App/constants';
import { KEYWORD_PAGE_SIZE } from '../../../Keywords/constants';
import sections from '../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';

type KeywordArticlesListProps = {
  keywordPage: Keyword;
};

export type KeywordArticlesListPropsInner = Pick<
  RouterProps,
  'location' | 'page'
> &
  KeywordArticlesListProps;

const KeywordArticlesList = ({
  keywordPage,
  page,
  location,
}: KeywordArticlesListPropsInner): ReactElement => {
  if (!keywordPage || !keywordPage.entities || !keywordPage.entities.edges) {
    return (
      <TestFragment data-testid="keywordarticlelist-not-found">
        <StatusPage />
      </TestFragment>
    );
  }

  const char =
    (keywordPage.label &&
      keywordPage.label.length >= 1 &&
      keywordPage.label[0]) ||
    '';
  const breadcrumbItems: Omit<ActiveMenuTrailItemConnection, 'pageInfo'> = {
    count: 2,
    totalCount: 2,
    edges: [
      {
        node: {
          id: '',
          link: '/' + ROUTE_KEYWORDS,
          label: 'Mots-clés',
        },
      },
      {
        node: {
          id: '',
          link: `/${ROUTE_KEYWORDS}/${char}`,
          label: 'Mots-clés: ' + char.toUpperCase(),
        },
      },
    ],
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Background} />
      <div className={sections.Container}>
        <Helmet
          title={keywordPage.label}
          titleTemplate={`%s - ${SITE_TITLE}`}
        />

        <div>
          <Breadcrumbs
            title={keywordPage?.label || ''}
            items={breadcrumbItems}
            staticData
            pageUrl={location?.pathname || ''}
          />
          <Link
            path={'/' + ROUTE_KEYWORDS}
            className={styles.OverviewLink}
            label="Tous les mots-clés"
          />

          <div className={styles.TitleWrapper}>
            <h1 className={styles.Title}>
              {keywordPage.label || ''}
              <div className={styles.SubscribeButtonWrapper}>
                <SubscribeButton
                  label={keywordPage.label || ''}
                  type={getAlertItemTypeByTypename(keywordPage.__typename)}
                  id={Number(keywordPage.tid)}
                />
              </div>
            </h1>
          </div>
        </div>
      </div>

      {keywordPage.entities && (
        <TestFragment data-testid="keywordarticlelist-searchresult-wrapper">
          <SearchResult list={keywordPage.entities} page={page} />
        </TestFragment>
      )}
    </div>
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps) => ({
      title: `Mot-clé ${mapProps?.keywordPage?.label || ''}`,
      metaDescription:
        mapProps.keywordPage?.entities?.edges?.[0]?.node?.lead || '',
    }),
    getNodesCount: (mapProps) => mapProps.keywordPage?.entities?.count || 0,
    pageSize: KEYWORD_PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps: KeywordArticlesListPropsInner) =>
      mapProps?.keywordPage?.entities?.edges || [],
  }),
)(KeywordArticlesList);
