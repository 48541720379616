import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import ImageCaption from '../ImageCaption';
import { STYLE_INLINE_IMAGE_1200 } from '../../../../../../../shared/constants/images';
import { MULTI_COLUMNS_PARAGRAPH } from '../../../../../../../shared/constants/paragraphs';
import {
  TRACKING_CLASS_IMAGE_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../shared/constants/tracking';
// eslint-disable-next-line postcss-modules/no-unused-class
import styles from './styles.legacy.css';
import { ImageParagraphProps } from './typings';

export type ImageParagraphPropsInner = ImageParagraphProps;

const ImageParagraph = ({
  imageParagraph,
  plainImage = false,
  origin,
  ...props
}: ImageParagraphPropsInner): ReactElement => {
  if (!imageParagraph || !imageParagraph.image) {
    return null;
  }

  const { file: viewportImage, showOriginal, credit } = imageParagraph.image;
  const originalUrl = showOriginal ? viewportImage.origin : null;

  const { focalPointX, focalPointY, height, width, relativeOriginPath, alt } =
    viewportImage;

  let imgComp = (
    <Picture
      url={originalUrl}
      showOriginal={showOriginal}
      relativeOrigin={relativeOriginPath}
      alt={alt}
      style_320={STYLE_INLINE_IMAGE_1200}
      width={width}
      height={height}
      focalPointX={focalPointX}
      focalPointY={focalPointY}
    />
  );

  if (imageParagraph?.link?.path) {
    imgComp = <Link {...imageParagraph.link}>{imgComp}</Link>;
  }

  if (!plainImage) {
    return (
      <div
        data-testid="image-paragraph-wrapper"
        className={classNames(
          TRACKING_CLASS_PARAGRAPH,
          TRACKING_CLASS_IMAGE_PARAGRAPH,
          styles.ImageWrapper,
          {
            [styles.PortraitWrapper]:
              viewportImage &&
              width &&
              height &&
              width < height &&
              !imageParagraph.fullWidth &&
              origin !== MULTI_COLUMNS_PARAGRAPH,
          },
        )}
      >
        {imgComp}
        {!props.hideCaption && (
          <div className={styles.ImageCaptionWrapper}>
            <ImageCaption
              origin={origin}
              caption={imageParagraph.caption || ''}
              credit={credit || ''}
              suppressSource={imageParagraph.suppressSource}
            />
          </div>
        )}
      </div>
    );
  }

  return imgComp;
};

export default ImageParagraph;
