import React, { Suspense, lazy } from 'react';
import imageGalleryParagraphFactory from '../../../../../../../common/components/Paragraphs/components/ImageGalleryParagraph/factory';
import ClientSideOnly from '../../../../../../../common/components/ClientSideOnly';
import SuspenseFallback from '../../../../../../../common/components/SuspenseFallback';

const ImageGalleryFinal = lazy(
  () => import(/* webpackChunkName: "ImageGallery" */ '../../../ImageGallery'),
);

const ImageGalleryParagraph = imageGalleryParagraphFactory({
  ImageGallery: (props) => (
    <ClientSideOnly>
      <Suspense fallback={<SuspenseFallback />}>
        <ImageGalleryFinal {...props} />
      </Suspense>
    </ClientSideOnly>
  ),
});

export default ImageGalleryParagraph;
