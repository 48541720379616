/* istanbul ignore file */

import classNames from 'classnames';
import mailchimpSubscribeFormFactory from '../../../../../../../../../../../common/components/Paragraphs/components/MinistageParagraph/components/MinistageNewsletter/components/MailchimpSubscribeForm/factory';
import Button from '../../../../../../../ButtonWithLoading';
import InputField from '../../../../../../../InputField';
import { MAILCHIMP_LIST_REQUEST } from './mutations';
import grid from '../../../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const MailchimpSubscribeForm = mailchimpSubscribeFormFactory({
  InputField,
  Button,
  mutationQuery: MAILCHIMP_LIST_REQUEST,
  messages: {
    successSubmission:
      'Un courriel été envoyé à votre adresse mail. Veuillez cliquer sur le lien se trouvant dans ce courriel afin de confirmer votre inscription à la newsletter.',
    inputInstruction: 'Votre adresse mail',
    labelSubmission: `s'inscrire`,
    errorValidation: 'Veuillez saisir une adresse mail valide.',
  },
  styles: {
    Form: styles.Form,
    Row: grid.Row,
    InputWrapper: classNames(
      styles.InputWrapper,
      grid.ColSm16,
      grid.ColMd17,
      grid.ColXl18,
    ),
    EmailWrapper: styles.EmailWrapper,
    SuccessWrapper: classNames(
      styles.SuccessWrapper,
      grid.ColMd17,
      grid.ColXl18,
    ),
    SuccessTick: styles.SuccessTick,
    SuccessSubmission: styles.SuccessSubmission,
    ButtonWrapper: styles.ButtonWrapper,
  },
});

export default MailchimpSubscribeForm;
