/**
 * @file   teaser hero brandreport
 * @author Pascal Tan <pascal.tan@ringieraxelspringer.ch>
 * @date   2019-10-23
 *
 */

import React from 'react';
import TeaserHeroImageTile from '../TeaserHeroImageTile';
import { TEASER_HERO_BRANDREPORT_IDENTIFIER } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserHeroBrandReport = (props: TeaserFactoryProps) => (
  <TeaserHeroImageTile
    {...props}
    trackingSelector={TEASER_HERO_BRANDREPORT_IDENTIFIER}
  />
);

export default TeaserHeroBrandReport;
