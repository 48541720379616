/**
 * @file    user icon
 * @author  Steven Wolf <steven.wolf@ringieraxelspringer.ch>
 * @date    2019-11-16
 *
 */

import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g>
      <path d="M9.28 10.4c0 5.632 3.04 9.12 6.72 9.12s6.72-3.488 6.72-9.12c0-4.896-2.656-7.52-6.72-7.52S9.28 5.504 9.28 10.4zm1.6 8.352l-3.648 1.504C3.68 21.728 2.88 23.904 2.88 26.88c0 1.568.864 2.24 2.08 2.24h22.08c1.152 0 2.08-.672 2.08-2.24 0-2.976-.832-5.152-4.384-6.624l-3.616-1.504-.896 2.048 3.648 1.504c2.496 1.024 2.976 2.4 3.008 4.576H5.12c0-2.176.512-3.552 3.008-4.576l3.648-1.504-.896-2.048zm.64-8.352c0-3.424 1.76-5.28 4.48-5.28s4.48 1.856 4.48 5.28c0 4.288-2.016 6.88-4.48 6.88s-4.48-2.592-4.48-6.88z" />
    </g>
  </svg>
);
