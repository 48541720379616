import React from 'react';
import TeaserLImageTile from '../TeaserLImageTile';
import { TEASER_L_BRANDREPORT_IDENTIFIER } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserLBrandReport = (props: TeaserFactoryProps) => (
  <TeaserLImageTile
    {...props}
    trackingSelector={TEASER_L_BRANDREPORT_IDENTIFIER}
  />
);

export default TeaserLBrandReport;
