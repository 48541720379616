import React from 'react';
import { connect } from 'react-redux';
import { setStatusCode } from '../../../../../../../shared/actions/ssr';
import styles from './styles.legacy.css';

const EmptyResult = ({ setStatusCode }) => {
  if (__SERVER__) {
    setStatusCode(404);
  }

  return (
    <div className={styles.EmptyResultWrapper}>
      <h1 className={styles.EmptyResultTitle}>
        Aucun résultat pour votre recherche.
      </h1>
      <span className={styles.EmptyResultSubtitle}>
        Pour la prochaine recherche:
      </span>
      <ul className={styles.EmptyResultTips}>
        <li className={styles.EmptyResultTip}>
          Vérifiez que les mots sont orthographiés correctement.
        </li>
        <li className={styles.EmptyResultTip}>Essayez d’autres mots-clés.</li>
        <li className={styles.EmptyResultTip}>
          Utilisez des mots-clés plus généraux.
        </li>
      </ul>
    </div>
  );
};

const mapDispatchToProps = {
  setStatusCode,
};

export default connect(null, mapDispatchToProps)(EmptyResult);
