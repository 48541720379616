import { ROUTE_HOME_PME } from '../../../shared/constants/publications';
import variables from './assets/styles/variables.legacy.css';

export const MAIN_CONTENT_ID = 'main';
export const SITE_TITLE = 'PME';
export const DEFAULT_PUBLICATION = 'PME';
export const PUBLISHER = 'pme.ch';
export const MODAL_ROOT_ID = 'modal-root';
export const SOCIAL_MEDIA_LINK_FACEBOOK =
  'https://www.facebook.com/pmemagazine/';
export const SOCIAL_MEDIA_LINK_LINKEDIN =
  'https://www.linkedin.com/company/pme-magazinech/';
export const SOCIAL_MEDIA_LINK_TWITTER = 'https://twitter.com/PMEmagazine_';
export const SOCIAL_MEDIA_LINK_GOOGLE_NEWS =
  'https://news.google.com/publications/CAAqBwgKMP2H_Aowj_3oAg?hl=de&gl=CH&ceid=CH%3Ade';
export const BRIGHTCOVE_ACCOUNT_ID = '2112711546001';
export const BRIGHTCOVE_PLAYER_ID = 'AV0Cm70Z4';

export const CHANNELS_VOCABULARY = 'channels_pme';
export const CHANNEL_TYPE_BLOGS = 'Blog';
export const CHANNEL_TYPE_VIDEOS = 'VideoBlog';
export const CHANNEL_TYPE_VIDEO_BLOG = 'video_blog';
export const OVERVIEW_VISIBLE_TYPE_BLOGS = 'Blogs';
export const OVERVIEW_VISIBLE_TYPE_VIDEOS = 'Videos';

export const ROUTE_HOME = ROUTE_HOME_PME;
export const ROUTE_451 = '451';
export const ROUTE_VIDEOS = 'videos';
export const ROUTE_BRAND_REPORT = 'brandreport';
export const ROUTE_ACCOUNT = 'account';
export const ROUTE_ALERTS = 'profile/alerts';
export const ROUTE_UNSUBSCRIBE_EMAIL_ALERTS = `${ROUTE_ALERTS}/unsubscribe/email`;
export const ROUTE_BOOKMARKS = 'profile/merkliste';
export const ROUTE_KEYWORDS = 'mots-cles';
export const ROUTE_LOGOUT = 'logout';
export const ROUTE_SEARCH = 'search';
export const ROUTE_STYLEGUIDE = 'styleguide';
export const ROUTE_STYLEGUIDE_PARAGRAPHS = 'styleguide/paragraphs';
export const ROUTE_STYLEGUIDE_TYPOGRAPHY = 'styleguide/typography/*';
export const ROUTE_AUTHORS = 'nos-redacteurs';

export const NEWSLETTER_TRACKING_PARAMS =
  '?promo_name=newsletterbutton&promo_position=header';
export const SUBSCRIPTION_URL = 'https://boutique.pme.ch/abonnements';
export const NEWSLETTER_URL = '/newsletters';
export const SUBSCRIPTION_TRACKING_PARAMS =
  '?promo_name=abobutton&promo_position=header';

export const GOOGLE_RECAPTCHA_KEY = '6Lc-pPYZAAAAAO0E1j9fUfihwdlDPG78FWwgE9CV';
export const ROUTES_WITH_CUSTOM_PIANO_TRACKING = [ROUTE_451];

export const ARTICLE_SCROLL_OFFSET = parseInt(variables.headerHeight, 10);
