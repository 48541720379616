import React from 'react';
import TeaserLImageTile from '../TeaserLImageTile';
import { TEASER_CTA_SPECIAL } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserLSpecial = (props: TeaserFactoryProps) => (
  <TeaserLImageTile {...props} cta={TEASER_CTA_SPECIAL} />
);

export default TeaserLSpecial;
