import React from 'react';

import TeaserLImageTile from '../TeaserLImageTile';
import { TEASER_CTA_DOSSIER } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserLDossier = (props: TeaserFactoryProps) => (
  <TeaserLImageTile {...props} cta={TEASER_CTA_DOSSIER} />
);

export default TeaserLDossier;
