import React, { ReactElement, memo } from 'react';
import Header from '../Header';
import { TRACKING_CLASS_SITE_HEADER } from '../../../../../shared/constants/tracking';
import { HeaderAreaComponent, HeaderAreaProps } from './typings';

type HeaderAreaPropsInner = HeaderAreaProps & {
  headerState?: HeaderState;
  subtypeValue: string;
};

const HeaderArea: HeaderAreaComponent = ({
  isStickyEnabled = true,
  isMarketingPage,
  subtypeValue,
}: HeaderAreaPropsInner): ReactElement => {
  return (
    <div className={TRACKING_CLASS_SITE_HEADER}>
      <Header
        isMarketingPage={isMarketingPage}
        hasStickiness={isStickyEnabled}
        subtypeValue={subtypeValue}
      />
    </div>
  );
};

export default memo<HeaderAreaPropsInner>(HeaderArea);
