/* istanbul ignore file */

import blockquoteParagraphFactory from '../../../../../../../common/components/Paragraphs/components/BlockquoteParagraph/factory';
import styles from './styles.legacy.css';

const BlockquoteParagraph = blockquoteParagraphFactory({
  styles: {
    Wrapper: '',
    Quote: styles.Quote,
    QuoteAuthor: styles.QuoteName,
  },
});

export default BlockquoteParagraph;
