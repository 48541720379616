import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';
import withProps from 'recompose/withProps';
import navigationStateSelector from '../../../../../shared/selectors/navigationStateSelector';
import ModalOverlay from '../ModalOverlay';
import NavigationMenu from './components/NavigationMenu';
import NavigationUserMenu from './components/NavigationUserMenu';
import { DEFAULT_MODAL_OVERLAY } from '../ModalOverlay/constants';
import {
  TYPE_NAVIGATION_MENU_DEFAULT,
  TYPE_NAVIGATION_MENU_FADEOUT,
  TYPE_NAVIGATION_MENU_USER,
} from './constants';
import { GET_NAVIGATION } from './queries.preload';

type NavigationPropsInner = {
  visibleNavigation: string;
  navigationUserMenu: Menu | null;
  navigationMenu: Menu | null;
};

const Navigation = ({ ...props }: NavigationPropsInner) => {
  switch (props.visibleNavigation) {
    case TYPE_NAVIGATION_MENU_USER: {
      // Note: the wrapping Fragment around the modal is necessary, otherwise it won't render correctly on SSR
      return __RINGIER_CONNECT_ENABLED__ ? (
        <div>
          <ModalOverlay component={DEFAULT_MODAL_OVERLAY} isVisible={true}>
            <NavigationUserMenu {...props} />
          </ModalOverlay>
        </div>
      ) : null;
    }
    case TYPE_NAVIGATION_MENU_FADEOUT: {
      // Note: the wrapping Fragment around the modal is necessary, otherwise it won't render correctly on SSR
      return (
        <div>
          <ModalOverlay component={DEFAULT_MODAL_OVERLAY} isVisible={false}>
            <NavigationUserMenu {...props} />
          </ModalOverlay>
        </div>
      );
    }
    case TYPE_NAVIGATION_MENU_DEFAULT:
    default: {
      // navigation has to be always on DOM for SEO reasons
      const isVisible =
        props.visibleNavigation === TYPE_NAVIGATION_MENU_DEFAULT;
      // Note: the wrapping Fragment around the modal is necessary, otherwise it won't render correctly on SSR
      return (
        <div>
          <ModalOverlay isVisible={isVisible} component={DEFAULT_MODAL_OVERLAY}>
            <NavigationMenu {...props} />
          </ModalOverlay>
        </div>
      );
    }
  }
};

const mapStateToProps = (state) => ({
  visibleNavigation: navigationStateSelector(state).visibleNavigation,
});

const withUpdatePolicy = shouldUpdate(
  (props, nextProps) => props.visibleNavigation !== nextProps.visibleNavigation,
);

const withState = connect(mapStateToProps);

const withData = withProps(GET_NAVIGATION);

export default compose(withData, withState, withUpdatePolicy)(Navigation);
