import React from 'react';

const SocialTwitter = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={true}
  >
    <path d="M17.5222 14.7749L23.4786 8H22.0671L16.8952 13.8826L12.7644 8H8L14.2466 16.8955L8 24H9.41155L14.8732 17.7878L19.2357 24H24L17.5222 14.7749ZM15.5889 16.9738L14.956 16.0881L9.92015 9.03975H12.0882L16.1522 14.728L16.7851 15.6137L22.0678 23.0076H19.8998L15.5889 16.9738Z" />
  </svg>
);

export default SocialTwitter;
