import { gql } from '@apollo/client';

export const GET_LATEST_TICKER_ARTICLES = gql`
  query GetLatestTickerArticles(
    $channel: Int!
    $limit: Int
    $contentTypes: [ContentTypeEnum]
  ) {
    environment(publication: PME) {
      content(channel: $channel, limit: $limit, contentTypes: $contentTypes) {
        edges {
          node {
            ... on Article {
              title
              canonicalUri
              preferredUri
              publicationDate
              channel {
                tid
                title
              }
              useAutoHyphens
            }
          }
        }
      }
    }
  }
`;
