import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import searchStateSelector from '../../../../../shared/selectors/searchStateSelector';
import { searchToggle } from '../../../../../shared/actions/search';
import Icon from '../Icon';
import ModalOverlay from '../ModalOverlay';
import SearchForm from '../SearchForm';
import { SEARCH_MODAL_OVERLAY } from '../ModalOverlay/constants';
import { SEARCH_FORM_THEME_WHITE } from '../SearchForm/constants';
import styles from './styles.legacy.css';
import grid from '../../../../../common/assets/styles/grid.legacy.css';

// ---------------------------------------------------------------------------------- //
// COMPONENT
// ---------------------------------------------------------------------------------- //

const SearchOverlay = ({ visibleSearch, searchToggle, handleSearchClose }) => {
  if (!visibleSearch) {
    return null;
  }

  const menuCloseHandler = () => {
    searchToggle(false);
  };

  return (
    <div>
      <ModalOverlay component={SEARCH_MODAL_OVERLAY} isVisible={visibleSearch}>
        <>
          <a
            onClick={handleSearchClose}
            className={styles.CloseLink}
            href="#close-search"
          >
            <Icon type="IconXMark" addClass={styles.Icon} />
          </a>
          <div className={grid.Container}>
            <SearchForm
              menuCloseHandler={menuCloseHandler}
              placeholder="Entrer mot-clé"
              theme={SEARCH_FORM_THEME_WHITE}
              focusOnMount
              focusOnMountDelay={300}
            />
          </div>
        </>
      </ModalOverlay>
    </div>
  );
};

// ---------------------------------------------------------------------------------- //
// RECOMPOSE
// ---------------------------------------------------------------------------------- //

const mapStateToProps = (state) => ({
  visibleSearch: searchStateSelector(state).visible,
});

/**
 * map dispatch to props
 *
 * @desc    creates dispatch actions and maps them to props
 * @returns {Object}
 */
const mapDispatchToProps = {
  searchToggle,
};

const extendWithHandlers = withHandlers({
  handleSearchClose: (props) => (event) => {
    event.preventDefault();
    props.searchToggle(false);
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  extendWithHandlers,
)(SearchOverlay);
