/* istanbul ignore file */

import alertsProfileFactory from '../../../../../common/screens/Account/components/AlertsProfile/factory';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import { setLoading, setScreenReady } from '../../../../shared/actions/route';
import AlertList from '../../components/AlertList';
import Helmet from '../../components/Helmet';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoginForm from './components/LoginForm';
import NoItems from './components/NoItems';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const AlertsProfile = alertsProfileFactory({
  styles: {
    AlertsProfileWrapper: styles.AlertsProfileWrapper,
    AlertListWrapper: grid.Container,
    LoginWrapper: styles.LoginWrapper,
    Title: styles.Title,
    Description: styles.Description,
    ItemsWrapper: grid.ColSm18,
  },
  descriptionText:
    "Suivez vos thèmes préférés. Nous vous tiendrons informé.e par e-mail de la publication d'un nouvel article sur vos thèmes préférés.",
  loginText: 'Veuillez vous connecter pour ajouter des thèmes à suivre',
  titleText: 'Alertes par e-mail',
  Helmet,
  grid,
  LoginForm,
  NoItems,
  LoadingSpinner,
  AlertList,
  setLoading,
  setScreenReady,
});

export default withAppNexus({
  parseTrackingData: (props) =>
    parseTrackingData({
      ...props,
      articleType: 'LandingPage',
    }),
})(AlertsProfile);
