import React from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import {
  STYLE_1X1_140,
  STYLE_1X1_210,
  STYLE_1X1_250,
} from '../../../../../../../shared/constants/images';
import defaultStyles from '../../shared/defaultStyles.legacy.css';
import styles from './styles.legacy.css';

const TeaserRanking = ({ node, showRankingPosition = true }) => {
  return (
    <Link path={node.preferredUri}>
      <div className={`teaser-ranking ${styles.Wrapper}`}>
        <div className={styles.Header}>
          {showRankingPosition && node.rankingPosition && (
            <div className={styles.RankingPosition}>{node.rankingPosition}</div>
          )}
        </div>
        {node.teaserImage?.image?.file?.relativeOriginPath && (
          <div
            className={classNames(
              defaultStyles.TeaserImageWrapper,
              styles.TeaserImageWrapper,
            )}
          >
            <Picture
              relativeOrigin={node.teaserImage?.image?.file?.relativeOriginPath}
              style_320={STYLE_1X1_140}
              style_760={STYLE_1X1_210}
              style_960={STYLE_1X1_250}
              className={styles.Image}
              alt={node.shortTitle || ''}
              focalPointX={node.teaserImage?.image?.file?.focalPointX}
              focalPointY={node.teaserImage?.image?.file?.focalPointY}
            />
          </div>
        )}
        <div className={styles.ContentWrapper}>
          <h3>
            <div className={styles.TeaserTitle}>{node.title || ''}</div>
          </h3>
          {node.shortTitle && (
            <p className={styles.ShortTitle}>{node.shortTitle}</p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default TeaserRanking;
