/* istanbul ignore file */

import commentBodyFactory from '../../../../../../../common/components/Comments/components/CommentBody/factory';
import {
  TIME_ELAPSED_FORMAT_LONG,
  getFormattedElapsedDate,
} from '../../../../../../../shared/helpers/dateTimeElapsed';
import Logo from '../../../Logo';
import { COMMENT_REDAKTION_PME } from '../../../../../../../shared/constants/comments';
import styles from './styles.legacy.css';
export default commentBodyFactory({
  publicationAccountName: COMMENT_REDAKTION_PME,
  logo: Logo,
  logoAlt: 'PME logo',
  getFormattedElapsedDate,
  elapsedDateFormat: TIME_ELAPSED_FORMAT_LONG,
  styles: {
    Body: styles.Body,
    Date: styles.Date,
    Logo: styles.Logo,
    Name: styles.Name,
    Text: styles.Text,
  },
});
