import React, { ComponentType } from 'react';
import classNames from 'classnames';
import Picture from '../../../../../../../common/components/Picture';
import VideoParagraph from '../../../Paragraphs/components/VideoParagraph';
import SponsorBanner from '../shared/components/SponsorBanner';
import { STYLE_SCALEW_280 } from '../../../../../../../shared/constants/images';
import variables from '../../../../../App/assets/styles/variables.legacy.css';
import styles from './styles.legacy.css';
import { VideoProps } from './typings';

type HeroVideoProps = VideoProps;

const HeroVideo: ComponentType<HeroVideoProps> = ({
  video,
  sponsor = null,
  children,
}) => (
  <div className={classNames('hero-video', styles.Wrapper)}>
    {sponsor?.teaserImage?.image?.file.relativeOriginPath && (
      <SponsorBanner
        isLabelOnTop
        sponsor={sponsor}
        label={sponsor?.prefix || 'Präsentiert von'}
        backgroundColor={sponsor?.colorCode || variables.white}
      >
        <Picture
          style_320={STYLE_SCALEW_280}
          relativeOrigin={sponsor.teaserImage.image.file.relativeOriginPath}
          alt={sponsor?.teaserImage?.image?.file?.alt || ''}
          className={styles.SponsorBannerLogo}
          focalPointX={sponsor?.teaserImage?.image?.file.focalPointX}
          focalPointY={sponsor?.teaserImage?.image?.file.focalPointY}
        />
      </SponsorBanner>
    )}
    <VideoParagraph video={video} addClass={styles.Video} />
    {children}
  </div>
);

export default HeroVideo;
