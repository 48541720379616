import React from 'react';
import TeaserMImageTile from '../TeaserMImageTile';
import { TEASER_CTA_DOSSIER } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserMDossier = (props: TeaserFactoryProps) => (
  <TeaserMImageTile {...props} cta={TEASER_CTA_DOSSIER} />
);

export default TeaserMDossier;
