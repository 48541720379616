import React from 'react';
import TeaserHeroImageTile from '../../../TeaserHero/components/TeaserHeroImageTile';
import { TEASER_CTA_CHANNEL } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserHeroLandingPage = (props: TeaserFactoryProps) => (
  <TeaserHeroImageTile {...props} cta={TEASER_CTA_CHANNEL} />
);

export default TeaserHeroLandingPage;
