import React from 'react';
import classNames from 'classnames';
import ArticleLead from '../ArticleLead';
import ArticleTitle from '../ArticleTitle';
import { default as Hero } from '../../../../components/Hero';
import {
  ADVERTISING_TYPE_BRANDREPORT,
  ARTICLE_TYPE_HEADLESS,
  ARTICLE_TYPE_OPINION,
  ARTICLE_TYPE_SEATCHANGE,
} from '../../../../../../../shared/constants/content';
import { IMAGE_PARAGRAPH } from '../../../../../../../shared/constants/paragraphs';
import { PUBLICATION_SWISS_INSURANCE } from '../../../../../../../shared/constants/publications';
import { TRACKING_CLASS_ARTICLE_HEADER } from '../../../../../../../shared/constants/tracking';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import { ArticleHeaderProps } from './typings';

const hasHeroImage = ({ subtypeValue }: ArticleHeaderProps) =>
  subtypeValue !== ARTICLE_TYPE_HEADLESS;

const ArticleHeader = ({ article }) => {
  const hasHero =
    hasHeroImage(article) &&
    article.heroImageBody &&
    Array.isArray(article.heroImageBody) &&
    article.heroImageBody.length > 0;
  return (
    <div
      className={classNames(
        TRACKING_CLASS_ARTICLE_HEADER,
        styles.Wrapper,
        sections.Section,
        {
          [styles.GoldenBackground]:
            article?.subtypeValue === ARTICLE_TYPE_OPINION ||
            article?.subtypeValue === ADVERTISING_TYPE_BRANDREPORT,
          [styles.IsSeatChange]:
            article?.subtypeValue === ARTICLE_TYPE_SEATCHANGE,
          [styles.GoldenBackgroundHero]:
            (article?.subtypeValue === ARTICLE_TYPE_OPINION ||
              article?.subtypeValue === ADVERTISING_TYPE_BRANDREPORT) &&
            hasHero,
        },
      )}
    >
      <div className={grid.Row}>
        <div className={sections.Container}>
          <ArticleTitle article={article} articleColStyle={grid.ColXs24} />
        </div>
      </div>

      <div className={sections.Container}>
        {article?.subtypeValue !== ARTICLE_TYPE_SEATCHANGE &&
          article &&
          hasHero && (
            <Hero
              heroImageBody={article.heroImageBody}
              article={article}
              addClass={styles.ImageWrapper}
            >
              {article.heroImageBody[0].__typename === IMAGE_PARAGRAPH && (
                <figcaption
                  className={classNames(styles.Caption, {
                    [styles.Gray]:
                      article.publication === PUBLICATION_SWISS_INSURANCE,
                  })}
                >
                  {article?.teaserImage?.caption && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: article.teaserImage.caption,
                      }}
                    />
                  )}
                  {article?.teaserImage?.image?.credit &&
                    !article.heroImageBody[0].suppressSource && (
                      <span
                        className={classNames(styles.Credit, {
                          [styles.Gray]:
                            article.publication === PUBLICATION_SWISS_INSURANCE,
                        })}
                      >
                        &#169; {article?.teaserImage?.image?.credit}
                      </span>
                    )}
                </figcaption>
              )}
            </Hero>
          )}
      </div>
      <div className={sections.Container}>
        <div className={grid.Row}>
          <ArticleLead article={article} articleColStyle={grid.ColXs24} />
        </div>
      </div>
    </div>
  );
};

export default ArticleHeader;
