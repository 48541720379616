/* istanbul ignore file */

import React from 'react';
import ministageTeaserFactory from '../../../../../../../../../common/components/Paragraphs/components/MinistageParagraph/components/MinistageTeaser/factory';
import ButtonWithLoading from '../../../../../ButtonWithLoading';
import { STYLE_SCALEW_280 } from '../../../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import { ButtonWithLoadingType } from '../../../../../../../../../common/components/ButtonWithLoading/typings';

const Button: ButtonWithLoadingType = ({ clickHandler, text, isLoading }) => (
  <ButtonWithLoading onClick={clickHandler} loading={isLoading}>
    {text}
  </ButtonWithLoading>
);

const MinistageTeaser = ministageTeaserFactory({
  styles: {
    ShortTitle: styles.ShortTitle,
    Wrapper: styles.Wrapper,
    Container: styles.Container,
    ContentWrapper: styles.ContentWrapper,
    Lead: styles.Lead,
    Headline: styles.Headline,
    LinkButton: styles.LinkButton,
    ImageWrapper: styles.ImageWrapper,
    TeaserImage: styles.TeaserImage,
  },
  imageStyles: {
    style_320: STYLE_SCALEW_280,
  },
  Button,
});

export default MinistageTeaser;
