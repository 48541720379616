import React from 'react';
import { useDispatch } from 'react-redux';
import { getMatchingTouchIcon } from '../../../../../shared/helpers/getMatchingTouchIcon';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import { setStatusCode } from '../../../../../shared/actions/ssr';
import { setScreenReady } from '../../../../shared/actions/route';
import Redirect from '../../../../../common/components/Redirect';
import ArticlePage from '../../screens/ArticlePage';
import Author from '../../screens/Author';
import ExplainingArticle from '../../screens/ExplainingArticle';
import ImageGalleryArticle from '../../screens/ImageGalleryArticle';
import KeywordArticlesList from '../../screens/Keywords/screens/KeywordArticlesList';
import LandingPage from '../../screens/LandingPage';
import LongRead from '../../screens/LongRead';
import PageScreen from '../../screens/PageScreen';
import Sponsor from '../../screens/Sponsor';
import Video from '../../screens/Video';
import Error from '../Error';
import StatusPage from './../../screens/StatusPage';
import {
  ADVERTISING_TYPE_LONGFORM,
  ARTICLE_CONTENT_TYPE,
  ARTICLE_TYPE_LONG_READ,
  AUTHOR_CONTENT_TYPE,
  EXPLAINING_ARTICLE_CONTENT_TYPE,
  IMAGE_GALLERY_CONTENT_TYPE,
  KEYWORD_CONTENT_TYPE,
  LANDING_PAGE_CONTENT_TYPE,
  NATIVE_ADVERTISING_CONTENT_TYPE,
  PAGE_CONTENT_TYPE,
  SPONSOR_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../shared/constants/content';
import { ROUTE_HOME } from '../../../App/constants';
import appleTouchIcon114 from '../../assets/graphics/favicon/apple-icon-114x114.png';
import appleTouchIcon144 from '../../assets/graphics/favicon/apple-icon-144x144.png';
import appleTouchIcon57 from '../../assets/graphics/favicon/apple-icon-57x57.png';
import appleTouchIcon120 from '../../assets/graphics/favicon/apple-touch-icon-120x120.png';
import appleTouchIcon152 from '../../assets/graphics/favicon/apple-touch-icon-152x152.png';
import appleTouchIcon180 from '../../assets/graphics/favicon/apple-touch-icon-180x180.png';
import { StatusCode } from '../../../../../common/screens/StatusPage/typings';
import { RasRouterProps } from './typings';

type RouterPropsInner = RasRouterProps;

const Router = ({ data, loading, location, page, error }: RouterPropsInner) => {
  const dispatch = useDispatch();
  const routeByPath: any =
    (data && data?.environment && data?.environment?.routeByPath) || null;

  // handle soft 301 for apple-touch-icons (https://getoutofmyhead.dev/apple-touch-icons)
  if (/apple-touch-icon/.test(location?.pathname)) {
    const iconSizes = {
      '57x57': appleTouchIcon57,
      '114x114': appleTouchIcon114,
      '120x120': appleTouchIcon120,
      '144x144': appleTouchIcon144,
      '152x152': appleTouchIcon152,
      '167x167': appleTouchIcon180,
      '180x180': appleTouchIcon180,
    };

    const touchIcon = getMatchingTouchIcon(iconSizes, location);

    if (__SERVER__) {
      dispatch(setStatusCode(301, touchIcon));
      return null;
    }
    global.history.replaceState({}, '', touchIcon + location.search);
  }

  if (error) {
    return <StatusPage statusCode={503} logMessage={error} />;
  }

  const statusCode: StatusCode = (routeByPath?.statusCode as StatusCode) || 404;

  // handle soft 404
  if (!routeByPath || !routeByPath.preferred) {
    return loading || data?.isStatic ? null : (
      <StatusPage statusCode={statusCode} />
    );
  }

  // handle soft 301
  if (location?.pathname) {
    const currentPathName = location.pathname;
    let preferredPathName = routeByPath.preferred || null;

    // hardcoded redirect from '/home-hz' to the main page
    if (currentPathName === `/${ROUTE_HOME}`) {
      preferredPathName = '/';
    }

    if (
      preferredPathName &&
      currentPathName !== preferredPathName &&
      preferredPathName !== `/${ROUTE_HOME}` &&
      currentPathName !== '/'
    ) {
      if (__SERVER__) {
        dispatch(setStatusCode(301, preferredPathName));
        return null;
      }
      // check extern redirect
      if (
        preferredPathName.indexOf('http://') === 0 ||
        preferredPathName.indexOf('https://') === 0
      ) {
        global.location.href = preferredPathName;
        return null;
      }

      dispatch(setScreenReady(false, { ...location }));

      const redirectTo = preferredPathName + location.search + location.hash;

      global.history.replaceState({}, '', redirectTo);
      return <Redirect to={redirectTo} />;
    }
  }

  // switch components
  switch (routeByPath.object.__typename) {
    case ARTICLE_CONTENT_TYPE:
    case NATIVE_ADVERTISING_CONTENT_TYPE: {
      if (routeByPath.object?.subtypeValue === ARTICLE_TYPE_LONG_READ) {
        return (
          <LongRead node={routeByPath.object} page={page} location={location} />
        );
      }
      if (routeByPath.object?.subtypeValue === ADVERTISING_TYPE_LONGFORM) {
        return (
          <PageScreen
            pageScreen={routeByPath.object}
            page={page}
            location={location}
          />
        );
      }
      return <ArticlePage article={routeByPath.object} location={location} />;
    }
    case EXPLAINING_ARTICLE_CONTENT_TYPE: {
      return (
        <ExplainingArticle article={routeByPath.object} location={location} />
      );
    }

    case IMAGE_GALLERY_CONTENT_TYPE:
      return (
        <ImageGalleryArticle
          imageGalleryArticle={routeByPath.object}
          location={location}
        />
      );
    case PAGE_CONTENT_TYPE:
      return (
        <PageScreen
          pageScreen={routeByPath.object}
          page={page}
          location={location}
        />
      );
    case LANDING_PAGE_CONTENT_TYPE:
      return (
        <LandingPage
          landingPage={routeByPath.object}
          page={page}
          location={location}
        />
      );
    case KEYWORD_CONTENT_TYPE:
      return (
        <KeywordArticlesList
          keywordPage={routeByPath.object}
          page={page}
          location={location}
        />
      );
    case SPONSOR_CONTENT_TYPE:
      return (
        <Sponsor
          component="brandReport"
          sponsor={routeByPath.object}
          location={location}
          page={page}
        />
      );
    case VIDEO_CONTENT_TYPE: {
      return <Video location={location} video={routeByPath.object} />;
    }
    case AUTHOR_CONTENT_TYPE:
      return <Author author={routeByPath.object} page={page} />;
    default:
      return (
        <Error
          msg={`Router: No Component for: ${routeByPath.object.__typename}`}
        />
      );
  }
};

export default withAppNexus({ parseTrackingData })(Router);
