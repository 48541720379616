import React from 'react';
import { ensureTeaserInterface } from '../../../../components/Teaser/shared/helpers';
import TeaserGrid from '../../../../components/TeaserGrid';
import { GRID_LAYOUT_TEASER_3X2_FIRST } from '../../../../components/TeaserGrid/gridConfigs/constants';
import styles from './styles.legacy.css';
import { SearchResultProps } from './typings';

const SearchResult = ({ list }: SearchResultProps) => {
  if (!list.edges || !list.edges.length) {
    return null;
  }

  return (
    <div className={`search-result ${styles.Wrapper}`}>
      <TeaserGrid
        layout={GRID_LAYOUT_TEASER_3X2_FIRST}
        items={ensureTeaserInterface(list.edges)}
      />
    </div>
  );
};

export default SearchResult;
