/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  GLOBAL_SEARCH_FILTER_ARTICLE,
  GLOBAL_SEARCH_FILTER_LANDING_PAGE,
  GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
  GLOBAL_SEARCH_SORT_BY_RELEVANCE,
} from '../../../../../shared/constants/globalSearch';
import { PAGE_SIZE } from './constants';
import { GET_SEARCH_PAGE } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location, params }) => {
    const page = location?.query?.page || 1;
    const sortOrder = location?.query?.sort || GLOBAL_SEARCH_SORT_BY_RELEVANCE;
    const { query = '' } = params;
    return {
      query: GET_SEARCH_PAGE,
      variables: {
        query: query && `${query}*`,
        sort: sortOrder,
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        contentTypes: [
          GLOBAL_SEARCH_FILTER_ARTICLE,
          GLOBAL_SEARCH_FILTER_LANDING_PAGE,
          GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
        ],
        publication: 'PME',
        additionalPublications: [],
      },
      // Do not execute this query if no search string was provided.
      skip: !query,
    };
  },
};
