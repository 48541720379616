/* istanbul ignore file */

import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import ContentBoxFactory, {
  ContentBoxPropsInner,
} from '../../../../../../../common/components/ContentBox/factory';
import { noop } from '../../../../../../../shared/helpers/utils';
import { ensureTeaserInterfaceItem } from '../../../Teaser/shared/helpers';
import Error from '../../../../../../../cash/screens/App/components/Error';
import Teaser from '../../../Teaser';
import Skeleton from '../../../Teaser/components/TeaserText/components/Skeleton';
import { CONTENT_SOURCE_TICKER } from '../../../../../../../shared/constants/content';
import { PUBLICATION_GROUP_HZ } from '../../../../../../../shared/constants/publications';
import { TEASER_LAYOUT_TEXT } from '../../../../../../../shared/constants/teaser';
import { GET_LATEST_TICKER_ARTICLES } from '../../queries';
import styles from './styles.legacy.css';
import { ContentBoxProps } from '../../../../../../../common/components/ContentBox/typings';

const LINK_LABEL_DEFAULT = 'Alle News';

const TeaserRenderer = ({ contentBoxData }): ReactElement => {
  if (!contentBoxData.items && !Array.isArray(contentBoxData.items)) {
    return null;
  }

  return (
    <div className={styles.TeaserWrapper}>
      {contentBoxData.items.map((item, index) => (
        <div key={`content-box-item-${item.node.id || index}`}>
          <Teaser
            component={TEASER_LAYOUT_TEXT}
            contentBoxType={contentBoxData.contentBoxType}
            {...ensureTeaserInterfaceItem(item)}
          />
        </div>
      ))}
    </div>
  );
};

const getTitleByProps = ({ node }: ContentBoxProps) =>
  node?.title || (node.termReference as Channel).title || '';

const getLinkLabelByProps = ({ node }: ContentBoxProps) => {
  return node?.linkLabel || LINK_LABEL_DEFAULT;
};

const Ticker = ContentBoxFactory({
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
    Link: styles.Link,
    TeaserWrapper: styles.TeaserWrapper,
  },
  TeaserGridRenderer: () => TeaserRenderer,
  getContentBoxRowGridOptions: noop,
  teaserLayout: null,
  Skeleton,
  publication: PUBLICATION_GROUP_HZ,
  contentBoxType: CONTENT_SOURCE_TICKER,
  linkLabel: getLinkLabelByProps,
  title: getTitleByProps,
});

const TickerWrapper = (props: ContentBoxPropsInner) => {
  const { data, error } = useQuery(GET_LATEST_TICKER_ARTICLES, {
    variables: {
      channel: parseInt((props?.node?.termReference as Channel)?.tid),
      limit: 6,
      contentTypes: ['Article'],
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return __DEVELOPMENT__ ? (
      <Error msg={`Something went wrong: ${error}`} />
    ) : null;
  }

  if (!data || !data?.environment?.content?.edges) {
    return null;
  }

  return (
    <Ticker
      {...props}
      latestArticles={data?.environment?.content?.edges || []}
    />
  );
};

export default TickerWrapper;
