/**
 * @file   teaser hero dossier
 * @author Pascal Tan <pascal.tan@ringieraxelspringer.ch>
 * @date   2019-10-23
 *
 */

import React from 'react';
import TeaserHeroImageTile from '../TeaserHeroImageTile';
import { TEASER_CTA_DOSSIER } from '../../../../constants';

const TeaserHeroDossier = (props) => (
  <TeaserHeroImageTile {...props} cta={TEASER_CTA_DOSSIER} />
);

export default TeaserHeroDossier;
