import React, { ReactElement } from 'react';
import ParagraphsRenderer from './components/ParagraphsRenderer';
import { ParagraphsProps } from './typings';

const Paragraphs = ({
  pageBody,
  showCap = false,
  applyDataFilter,
  ...props
}: ParagraphsProps): ReactElement => {
  if (!pageBody || !pageBody.length || pageBody.length < 1) {
    return null;
  }

  const body =
    typeof applyDataFilter === 'function'
      ? applyDataFilter(pageBody)
      : pageBody;

  return <ParagraphsRenderer showCap={showCap} {...props} pageBody={body} />;
};

export default Paragraphs;
