/**
 * @file    user active icon
 * @author  Steven Wolf <steven.wolf@ringieraxelspringer.ch>
 * @date    2019-11-16
 *
 */

import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g>
      <path d="M9.345 10.46c0 1.382.175 2.631.526 3.745s.831 2.063 1.439 2.848c.609.784 1.315 1.388 2.12 1.811s1.661.634 2.569.634c.908 0 1.764-.212 2.569-.634s1.512-1.027 2.12-1.811c.609-.784 1.088-1.733 1.439-2.848s.526-2.363.526-3.745c0-1.218-.16-2.29-.48-3.219s-.769-1.707-1.346-2.337c-.578-.629-1.274-1.104-2.089-1.424S17.01 3 15.999 3s-1.924.16-2.739.48-1.512.794-2.089 1.424c-.578.629-1.027 1.408-1.346 2.337s-.48 2.002-.48 3.219zm1.579 8.264L7.303 20.21c-1.754.743-2.909 1.656-3.467 2.739S3 25.306 3 26.772c0 .784.196 1.352.588 1.702S4.475 29 5.074 29h21.852c.578 0 1.068-.175 1.47-.526s.604-.918.604-1.702c0-1.465-.284-2.739-.851-3.823s-1.728-1.996-3.482-2.739l-3.59-1.486s-1.034 3.529-5.076 3.529-5.076-3.529-5.076-3.529z" />
    </g>
  </svg>
);
