import React from 'react';
import { enrichOverviewBodyWithADs } from '../../../../../../../shared/helpers/ads';
import { getRestrictedClassName } from '../../../../../../../shared/helpers/withHelmet';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import EditButtons from '../../../../components/EditButtons';
import Paragraphs from '../../../../components/Paragraphs';
import { LANDING_PAGE_TYPE_HOME } from '../../../../../../../shared/constants/content';
import { MINISTAGE_PARAGRAPH } from '../../../../../../../shared/constants/paragraphs';
import { ROOT_SCHEMA_TYPE_ORGANIZATION } from '../../../../../../../shared/constants/structuredData';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { LandingPageProps } from '../../typings';

type LandingPagePropsInner = LandingPageProps & { isAdSuppressed?: boolean };

const LandingPageHome = ({
  landingPage,
  origin,
  isAdSuppressed,
}: LandingPagePropsInner) => {
  if (!landingPage) {
    return null;
  }

  // Our stack is only working correctly for ADs with an EQ on first position
  // because of the weird rule to show either MMR1 on top OR inside the EQ on second position
  // These Ministages on HZ are an exception to that rule so we take it out of the ADs logic here.
  let ministageHeaderParagraph = [];
  let landingPageBody = landingPage.body as MinistageParagraph[];
  if (
    Array.isArray(landingPageBody) &&
    landingPageBody.length > 0 &&
    landingPageBody[0].__typename === MINISTAGE_PARAGRAPH
  ) {
    ministageHeaderParagraph = landingPageBody.slice(0, 1);
    landingPageBody = landingPageBody.slice(1);
  }

  const preparedBodyWithAds = enrichOverviewBodyWithADs({
    pageBody: landingPageBody,
    hasEQsWithMMR: true,
  });

  return (
    <div className={`landing-page ${styles.Wrapper}`}>
      <EditButtons
        editContentUri={landingPage.editContentUri}
        editRelationUri={landingPage.editRelationUri}
        cloneContentUri={landingPage.cloneContentUri}
      />

      <div className={getRestrictedClassName(landingPage.__typename)}>
        <Paragraphs
          pageBody={[...ministageHeaderParagraph, ...preparedBodyWithAds]}
          colStyle={grid.ColXs24}
          origin={origin || LANDING_PAGE_TYPE_HOME}
          isAdSuppressed={landingPage?.channel?.suppressAds || isAdSuppressed}
        />
      </div>
    </div>
  );
};

export default withHelmet({
  getNode: (mapProps) => mapProps.landingPage,
  rootSchemaType: ROOT_SCHEMA_TYPE_ORGANIZATION,
})(LandingPageHome);
