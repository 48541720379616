import React from 'react';
import TeaserXSImageTile from '../TeaserXSImageTile';
import { TEASER_XS_BRANDREPORT_IDENTIFIER } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserXSBrandReport = (props: TeaserFactoryProps) => (
  <TeaserXSImageTile
    {...props}
    trackingSelector={TEASER_XS_BRANDREPORT_IDENTIFIER}
  />
);

export default TeaserXSBrandReport;
