import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import withApolloScreenHandlerFactory from '../../../shared/decorators/withApolloScreenHandlerFactory';
import { setActivePublication } from '../../../shared/actions/navigation';
import { setScreenReady } from '../actions/route';
import { PUBLICATION_PME } from '../../../shared/constants/publications';

const mapDispatchToProps = {
  setActivePublication,
};

const updatePublication = ({ data, setActivePublication }) => {
  const routeObject = data?.routeByPath?.object;
  const publication = routeObject?.publication || PUBLICATION_PME;

  if (publication && setActivePublication) {
    setActivePublication(publication);
  }
};

const withUpdatedActivePublication = (Component) => (props) => {
  updatePublication(props);
  return <Component {...props} />;
};

export default (Component) =>
  compose(
    connect(null, mapDispatchToProps),
    withUpdatedActivePublication,
    withApolloScreenHandlerFactory({ setScreenReady }),
  )(Component);
