import {
  ADVERTISING_TYPE_ADVERTORIAL,
  ADVERTISING_TYPE_ADVERTORIAL_LABEL,
  ADVERTISING_TYPE_LONGFORM,
  ADVERTISING_TYPE_NATIVE_ARTICLE,
  ARTICLE_TYPE_OPINION,
} from '../../../shared/constants/content';

export const generateShortTitle = ({ subtypeValue, channelTitle }) => {
  const shortTitle =
    (subtypeValue === ADVERTISING_TYPE_NATIVE_ARTICLE &&
      'Native Advertising') ||
    ((subtypeValue === ADVERTISING_TYPE_ADVERTORIAL ||
      subtypeValue === ADVERTISING_TYPE_LONGFORM) &&
      ADVERTISING_TYPE_ADVERTORIAL_LABEL) ||
    (subtypeValue === ARTICLE_TYPE_OPINION && 'Commentaire') ||
    channelTitle ||
    '';

  return shortTitle;
};
