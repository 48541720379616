import locationStateSelector from '../../../shared/selectors/locationStateSelector';
import windowStateSelector from '../../../shared/selectors/windowStateSelector';
import withAppNexusFactory from '../../../shared/decorators/withAppNexusFactory';
import {
  mapPathSegmentFullPath,
  mapPathSegmentToVertical,
} from '../reducers/route';
import { DEFAULT, HOME } from '../actions/route';
import { mapViewportToAdViewport } from '../../screens/App/components/AppNexus';

export const mapVerticalToAdCategory = () => ({
  [DEFAULT]: 'ROS',
  [HOME]: 'Home',
});

export default withAppNexusFactory({
  mapViewportToAdViewport,
  locationStateSelector,
  windowStateSelector,
  mapPathSegmentToVertical,
  mapPathSegmentToOnmeda: mapPathSegmentFullPath,
  mapVerticalToAdCategory,
  defaultVertical: DEFAULT,
});
