import React from 'react';
import TeaserMImageTile from '../TeaserMImageTile';
import { TEASER_CTA_SPECIAL } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserMSpecial = (props: TeaserFactoryProps) => (
  <TeaserMImageTile {...props} cta={TEASER_CTA_SPECIAL} />
);

export default TeaserMSpecial;
