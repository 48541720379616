import React from 'react';
import Teaser from '../Teaser';
import { TEASER_LAYOUT_SPONSOR } from '../../../../../shared/constants/teaser';
import { SPONSORED_BY_TYPE } from './constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';

// ---------------------------------------------------------------------------------- //
// RENDER
// ---------------------------------------------------------------------------------- //

const renderSponsors = ({ node }, index) => (
  <div className={grid.ColSm8} key={`render-sponsor-item-${index}`}>
    <div className={styles.SponsorItem}>
      <Teaser
        component={TEASER_LAYOUT_SPONSOR}
        {...node}
        origin={SPONSORED_BY_TYPE}
      />
    </div>
  </div>
);

const SponsoredBy = ({ title, sponsors }) => {
  if (!sponsors || !sponsors.edges || sponsors.edges.length === 0) {
    return null;
  }
  return (
    <div className={styles.Wrapper}>
      <section className={sections.Container}>
        {title && <h2 className={styles.Title}>{title}</h2>}
        <div className={grid.Row}>{sponsors.edges.map(renderSponsors)}</div>
      </section>
    </div>
  );
};

export default SponsoredBy;
