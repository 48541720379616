/* istanbul ignore file */

import React from 'react';
import noItemsFactory from '../../../../../../../common/components/NoItems/factory';
import Button from '../../../../components/ButtonWithLoading';
import Icon from '../../../../components/Icon';
import styles from './styles.legacy.css';
import { useStableNavigate } from '../../../../../../../shared/hooks/useStableNavigateContext';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [styles.BellAnimation];

const NoItemsButton = () => {
  const navigate = useStableNavigate();
  return (
    <Button onClick={() => navigate('/')} loading={false}>
      Vers la page d’accueil
    </Button>
  );
};

export default noItemsFactory({
  styles: {
    NoItemsWrapper: '',
    InnerWrapper: styles.InnerWrapper,
    Text: styles.Text,
    Icon: styles.Icon,
    Wrapper: styles.Wrapper,
  },
  Icon,
  text: 'Avec ce symbole, vous enregistrez les articles qui vous intéressent pour les lire plus tard.',
  button: <NoItemsButton />,
  iconType: 'IconBookmark',
});
