import React, { ComponentType } from 'react';
import classNames from 'classnames';
import Picture from '../../../../../../../common/components/Picture';
import SponsorBanner from '../shared/components/SponsorBanner';
import {
  STYLE_16X9_1130,
  STYLE_16X9_560,
  STYLE_16X9_700,
  STYLE_16X9_890,
  STYLE_SCALEW_280,
} from '../../../../../../../shared/constants/images';
import variables from '../../../../../App/assets/styles/variables.legacy.css';
import styles from './styles.legacy.css';
import { HeroImageProps } from './typings';

type HeroImagePropsInner = HeroImageProps & {
  image: Image & {
    image: {
      file: {
        relativeOriginPath: string;
        alt: string;
        focalPointX: number;
        focalPointY: number;
      };
    };
  };
};

const HeroImage: ComponentType<HeroImagePropsInner> = ({
  image,
  sponsor = null,
  addClass = '',
  children,
}) => {
  if (!image?.image?.file.relativeOriginPath) {
    return null;
  }

  const { focalPointX, focalPointY, relativeOriginPath, alt } =
    image.image.file;

  return (
    <figure
      className={classNames('hero-image', styles.Wrapper, {
        [addClass]: !!addClass,
      })}
    >
      {sponsor?.teaserImage?.image?.file.relativeOriginPath && (
        <SponsorBanner
          isLabelOnTop
          sponsor={sponsor}
          label={sponsor?.prefix || 'Präsentiert von'}
          backgroundColor={sponsor?.colorCode || variables.white}
        >
          <Picture
            style_320={STYLE_SCALEW_280}
            relativeOrigin={sponsor.teaserImage.image.file.relativeOriginPath}
            alt={sponsor?.teaserImage?.image?.file?.alt || ''}
            className={styles.SponsorBannerLogo}
            focalPointX={sponsor?.teaserImage?.image?.file.focalPointX}
            focalPointY={sponsor?.teaserImage?.image?.file.focalPointY}
          />
        </SponsorBanner>
      )}
      <Picture
        relativeOrigin={relativeOriginPath}
        style_320={STYLE_16X9_560}
        style_540={STYLE_16X9_560}
        style_760={STYLE_16X9_700}
        style_960={STYLE_16X9_890}
        style_1680={STYLE_16X9_1130}
        alt={alt || ''}
        className={styles.Img}
        focalPointX={focalPointX}
        focalPointY={focalPointY}
      />
      {children}
    </figure>
  );
};

export default HeroImage;
