import React, { Component } from 'react';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { hasNextPage } from '../shared/helpers';
import { PagerFactoryOptions, PagerFactoryProps } from './typings';

type LazyLoaderPagerProps = {
  hasNextPage: () => boolean;
  handleNextPage: () => void;
};

type LazyLoaderPropsInner = PagerFactoryOptions &
  PagerFactoryProps & {
    pager?: LazyLoaderPagerProps;
  };

const LazyLoaderFactory = ({ styles, Icon }: LazyLoaderPropsInner) => {
  class LazyLoader extends Component<LazyLoaderPropsInner> {
    constructor(props: LazyLoaderPropsInner) {
      super(props);
    }

    render() {
      const { className, children, label = 'Mehr laden' } = this.props;

      // hide "load more" button if no further items are fetchable
      if (!this.props.pager.hasNextPage()) {
        return null;
      }

      return (
        <button
          onClick={() => this.props.pager.handleNextPage()}
          className={className || `lazy-loader-btn ${styles.LoadMore}`}
        >
          {children || (
            <>
              <Icon
                type="IconArrowRotateRight"
                addClass={styles.IconArrowRotateRight}
              />
              <span>{label}</span>
            </>
          )}
        </button>
      );
    }
  }

  const withPagerFunctionality = withPropsOnChange(
    (props: LazyLoaderPropsInner, nextProps: LazyLoaderPropsInner) =>
      props.currentPage !== nextProps.currentPage,
    (props: LazyLoaderPropsInner) => ({
      pager: {
        hasNextPage: () =>
          hasNextPage(props.currentPage, props.itemsCount, props.itemsPerPage),
        handleNextPage: (): void => props.updatePage(props.currentPage + 1),

        ...props.pager,
      },
    }),
  );

  return compose(withPagerFunctionality)(LazyLoader);
};

export default LazyLoaderFactory;
