import React from 'react';
import TeaserMImageTile from '../TeaserMImageTile';
import { TEASER_CTA_CHANNEL } from '../../../../constants';
import { TeaserFactoryProps } from '../../../../../../../../../common/components/Teaser/typings';

const TeaserMLandingPage = (props: TeaserFactoryProps) => (
  <TeaserMImageTile {...props} cta={TEASER_CTA_CHANNEL} />
);

export default TeaserMLandingPage;
