/* istanbul ignore file */

import React from 'react';
import compose from 'recompose/compose';
import statusPageFactory from './../../../../../common/screens/StatusPage/factory';
import withPianoTrackingData from '../../../../../shared/decorators/withPianoTrackingData';
import withApolloScreenHandler from '../../../../shared/decorators/withApolloScreenHandler';
import withWaitUntilApolloLoaded from '../../../../shared/decorators/withWaitUntilApolloLoaded';
import { setLoading, setScreenReady } from '../../../../shared/actions/route';
import Helmet from './../../components/Helmet';
import SVGIcon from './../../components/SVGIcon';
import SearchForm from './../../components/SearchForm';
import {
  SVG_ICONS_TYPE_PME_404,
  SVG_ICONS_TYPE_PME_451,
  SVG_ICONS_TYPE_PME_500,
} from './../../components/SVGIcon/constants';
import { SEARCH_FORM_THEME_BLUE } from './../../components/SearchForm/constants';
import styles from './styles.legacy.css';
import type { StatusCodeConfig } from './../../../../../common/screens/StatusPage/typings';

const statusCodeConfig: StatusCodeConfig = {
  404: {
    icon: <SVGIcon type={SVG_ICONS_TYPE_PME_404} className={styles.Icon} />,
    title: { text: 'Oups, cette page est introuvable' },
    description: {
      text: 'La page souhaitée n’existe pas. Allez en bas de page pour retrouver nos contenus ou utilisez notre moteur de recherche.',
    },
    metaTitle: 'Page introuvable',
  },
  451: {
    icon: <SVGIcon type={SVG_ICONS_TYPE_PME_451} className={styles.Icon} />,
    title: { text: 'Cet article n’est pas disponible dans votre pays' },
    description: {
      text: 'Veuillez nous excuser, mais cet article n’est pas disponible dans votre lieu de séjour.',
    },
    metaTitle: 'Cet article n’est pas disponible dans votre pays',
    showSearchForm: false,
  },
  503: {
    icon: <SVGIcon type={SVG_ICONS_TYPE_PME_500} className={styles.Icon} />,
    title: { text: 'Système hors service' },
    description: {
      text: 'Le système est en panne',
    },
    metaTitle: 'Hors Service',
  },
};

const StatusPage = statusPageFactory({
  statusCodeConfig,
  searchForm: (
    <SearchForm placeholder="Entrer mot-clé" theme={SEARCH_FORM_THEME_BLUE} />
  ),
  Helmet,
  styles: {
    Wrapper: styles.Wrapper,
    Container: styles.Container,
    Row: styles.Row,
    Columns: styles.Columns,
    HeaderWrapper: styles.HeaderWrapper,
    IconWrapper: styles.IconWrapper,
    Icon: styles.Icon,
    Title: styles.Title,
    Description: styles.Description,
    SearchWrapper: styles.SearchWrapper,
  },
  setLoading,
  setScreenReady,
});

export default compose(
  withApolloScreenHandler,
  withWaitUntilApolloLoaded,
  withPianoTrackingData,
)(StatusPage);
