import React from 'react';
import classNames from 'classnames';
import expansionPanelFactory from '../../../../../../../../../common/components/ExpansionPanel/factory';
import TestFragment from '../../../../../../../../../shared/tests/components/TestFragment';
import Logo from '../../../../../Logo';
import SocialMediaBar from '../../../../../SocialMediaBar';
import { SOCIAL_MEDIA_BAR_ORIGIN_NAVIGATION } from '../../../../../SocialMediaBar/constants';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

export const getHeader = () => (
  <div data-testid="expansion-panel-header">
    <div className={styles.Logo}>
      <Logo type="logo/logo-color-white" />
    </div>
    <div className={styles.SocialBarWrapper}>
      <TestFragment data-testid="expansion-panel-socialmedia-bar">
        <SocialMediaBar origin={SOCIAL_MEDIA_BAR_ORIGIN_NAVIGATION} />
      </TestFragment>{' '}
    </div>
  </div>
);

export default expansionPanelFactory({
  header: getHeader,
  styles: {
    ExpansionPanel: styles.ExpansionPanel,
    IsOpen: styles.IsOpen,
    Header: styles.Header,
    HeaderContentWrapper: classNames(
      grid.Container,
      styles.HeaderContentWrapper,
    ),
    Title: '',
    BoldTitle: '',
    Spacer: '',
    Icon: styles.Icon,
    ArrowIcon: styles.ArrowIcon,
    Content: styles.Content,
  },
});
