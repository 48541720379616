/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { GLOBAL_SEARCH_SORT_BY_RELEVANCE } from '../../../../../../../shared/constants/globalSearch';
import { ITEMS_LIMIT } from './constants';
import { GET_TERM_OCCURRENCE } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ params: { term } }) => {
    return {
      query: GET_TERM_OCCURRENCE,
      variables: {
        contentTypes: ['Article'],
        limit: ITEMS_LIMIT,
        publication: 'PME',
        sort: GLOBAL_SEARCH_SORT_BY_RELEVANCE,
        query: `${term}*`,
      },
    };
  },
};
