import React, { ReactElement } from 'react';
import classNames from 'classnames';
import MinistageNewsletterSignupDefault from './components/MinistageNewsletterSignupDefault';
import {
  TRACKING_CLASS_MINISTAGE_NEWSLETTER_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../../../shared/constants/tracking';
import { MinistageNewsletterProps } from './typings';

const MinistageNewsletter = (props: MinistageNewsletterProps): ReactElement => {
  return (
    <div
      className={classNames(
        TRACKING_CLASS_PARAGRAPH,
        TRACKING_CLASS_MINISTAGE_NEWSLETTER_PARAGRAPH,
      )}
    >
      <MinistageNewsletterSignupDefault
        ministageNewsletter={props.ministageNewsletter}
        addClass={props.addClass}
      />
    </div>
  );
};

export default MinistageNewsletter;
