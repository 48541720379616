import React, { memo } from 'react';
import { connect } from 'react-redux';
import selectLocationState from '../../../../../shared/selectors/locationStateSelector';
import LoadingBar from './components/LoadingBar';

const Loading = (props) =>
  !props.routeScreenReady || props.isRefetchingData ? <LoadingBar /> : null;

export const mapStateToProps = (state) => ({
  routeScreenReady: selectLocationState(state).screenReady,
  isRefetchingData: selectLocationState(state).isRefetchingData,
});

export default connect(mapStateToProps)(memo(Loading));
