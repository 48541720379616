//

const {
  BRANDINGDAY_CONTAINER_WIDTH,
  BREAKPOINTS,
  GRID,
  ZINDEXES,
  SPACING,
} = require('../../../../../common/assets/styles/variablesDefault.legacy.css');

module.exports = {
  // use global variables
  ...BRANDINGDAY_CONTAINER_WIDTH,
  ...BREAKPOINTS,
  ...GRID,
  ...ZINDEXES,
  ...SPACING,

  // colors
  blackA: 'rgba(0, 0, 0, 1)', // #000000
  blackB: 'rgba(41, 46, 50, 1)', // #292E32
  blackC: 'rgb(105, 105, 105)', //#696969
  errorA: 'rgba(197, 25, 65, 1)', // #c51942
  errorB: 'rgba(236, 182, 195, 1)', // #ecb6c3
  greyA: 'rgba(106, 110, 113, 1)', // #6A6E71
  greyB: 'rgba(160, 163, 166, 1)', // #A0A3A6
  greyC: 'rgba(216, 216, 216, 1)', // #D8D8D8
  greyD: 'rgba(239, 239, 239, 1)', //#EFEFEF
  greyE: 'rgba(245, 245, 245, 1)', //#F5F5F5
  decoA: 'rgba(224, 245, 239, 1)', //#E0F5EF
  decoB: 'rgba(240, 240, 250, 1)', //#F0F0FA
  decoC: 'rgba(247, 239, 232, 1)', //#F7EFE8
  primaryA: 'rgba(233, 1, 86, 1)', // #e90156
  primaryB: 'rgba(183, 1, 67, 1)', // #B70143
  primaryC: 'rgba(127, 1, 47, 1)', // #7f012f
  white: 'rgba(255, 255, 255, 1)', // #FFFFFF

  // not from styleguide
  grayPlaceholder: 'rgba(246, 246, 246,1)', // #f6f6f6
  logoutAnimatedDotsColor: '#292e32',

  // TOAST COLORS:
  success: 'rgba(1, 145, 100, 1)', //#019164,
  warning: 'rgba(255, 177, 31, 1)', //#FFB11F,
  error: 'rgba(213, 16, 48, 1)', //#D51030

  // DO NOT DELETE: colors used in /common/components that are not implemented with a factory
  smokeyWhite: 'rgba(166, 170, 173, 1)',
  black: 'rgba(0, 0, 0, 1)',

  transparent: 'transparent',
  none: 'none',
  inherit: 'inherit',

  // fonts
  fontRoboto: 'Roboto, Helvetica, Arial, Verdana, sans-serif',
  fontGeorgia: 'Georgia, "Times New Roman", serif',
  fontHelvetica: 'Helvetica, Arial, sans-serif',
  fontRasch: 'rasch-font',

  // z-indices
  zIndexBackground: -1,
  zIndexLow: 0,
  zIndex1: 1,
  zIndexMedium: 10,
  zIndexHigh: 100,
  zIndexHighest: 1000,
  zIndexOverlay: 1500,

  // header height
  headerHeight: '50px',
  headerHeightObserver: '51px',

  // gradients
  gradientLongReadBlack:
    'linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7) 100%)',
  gradientBlack: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%)',
  gradientShineWhite:
    'linear-gradient(20deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)',
  gradientWhite:
    'linear-gradient(180deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%)',
  gradientDossierImage:
    'linear-gradient(-180deg, rgba(39,39,39,.9) 0%, rgba(255,255,255,0) 60%)',
  brandRepGradientGlare:
    'linear-gradient(50deg, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 100%)',
  brandRepGradientDark:
    'linear-gradient(-166deg, rgba(255,255,255,0) 40%,rgba(0,0,0,0.2) 60%, rgba(0,0,0,.9) 90%)',
  brandRepGradientGlareSm:
    'linear-gradient(20deg, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%)',
  brandRepGradientDarkSm:
    'linear-gradient(-180deg, rgba(255,255,255,0) 26%, rgba(0,0,0,.2) 50%, rgba(0,0,0,.9) 90%)',
  purpleToBlueGradient: 'linear-gradient(180deg, $greyE 0%, $primaryC 100%)',
  blueToPurpleGradient: 'linear-gradient(180deg, $primaryC 0%, $greyE 100%)',
  organizationPagegradient:
    'linear-gradient(0deg, rgba(15,45,100,.9) 0%, #0A224D 100%)',

  // font sizes
  bodyFontSize: '16px',
  bodyLineHeight: '3.1rem',
  footerHeight: '46px',

  // others
  retinaFontScale: '1.1',
  retinaDpi: '192dpi',

  // transitions
  $navigationTransitionFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',

  /*  Colors with no equivalents in palette */
  blackAlpha4: 'rgba(0, 0, 0, .4)',
  blackAlpha5: 'rgba(0, 0, 0, .5)',
};
