import React from 'react';
import classNames from 'classnames';
import TeaserAuthor from '../../../../components/Teaser/components/TeaserAuthor';
import { PUBLICATION_PME } from '../../../../../../../shared/constants/publications';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { AuthorsTeaserProps } from './typings';

const AuthorsTeaser = ({ authors }: AuthorsTeaserProps) => {
  if (!authors || authors.length <= 0) {
    return null;
  }
  return (
    <div className={classNames(grid.Container, styles.AuthorsWrapper)}>
      <div className={grid.Row}>
        <div className={classNames(grid.ColMd18, grid.ColXl17)}>
          {authors
            .filter(
              ({ node: { hasProfilePage, preferredUri, publications } }) =>
                hasProfilePage &&
                preferredUri &&
                publications.includes(PUBLICATION_PME),
            )
            .map(({ node: author }) => (
              <div key={author.id}>
                <div className={styles.Divider} />
                <TeaserAuthor
                  author={author}
                  insideArticle
                  withAuthorImage
                  readMoreLabel={'Montrer plus'}
                />
              </div>
            ))}
          <div className={styles.Divider} />
        </div>
      </div>
    </div>
  );
};

export default AuthorsTeaser;
