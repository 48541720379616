import React, { ReactElement } from 'react';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import ElementList from '../../../../components/TermsOverview/components/ElementList';

export type SearchResultProps = {
  list?: KeywordGraphList;
};

const KeywordList = ({ list = {} }: SearchResultProps): ReactElement => {
  if (
    !list ||
    !list.edges ||
    !Array.isArray(list.edges) ||
    !list.edges.length
  ) {
    return null;
  }

  return (
    <TestFragment data-testid="keywordlist-elementlist-wrapper">
      <ElementList
        data={list.edges.filter((edge) => !!edge.node).map((edge) => edge.node)}
      />
    </TestFragment>
  );
};

export default KeywordList;
