/* istanbul ignore file */

import React from 'react';
import classNames from 'classnames';
import ContentBoxFactory from '../../../../../../../common/components/ContentBox/factory';
import { noop } from '../../../../../../../shared/helpers/utils';
import { ensureTeaserInterfaceItem } from '../../../Teaser/shared/helpers';
import Teaser from '../../../Teaser';
import Skeleton from '../../../Teaser/components/TeaserText/components/Skeleton';
import { CONTENT_SOURCE_MOST_READ } from '../../../../../../../shared/constants/content';
import { PUBLICATION_GROUP_PME } from '../../../../../../../shared/constants/publications';
import { TEASER_LAYOUT_TEXT } from '../../../../../../../shared/constants/teaser';
import styles from './styles.legacy.css';
import { ContentBoxProps } from '../../../../../../../common/components/ContentBox/typings';

const MOST_READ_TITLE = 'Most Read';

const TeaserRenderer = ({ contentBoxData }) => {
  if (
    !contentBoxData ||
    !Array.isArray(contentBoxData.items) ||
    contentBoxData.items.length === 0
  ) {
    return null;
  }

  return (
    <>
      {contentBoxData.items.map((item, index) => (
        <div
          key={`content-box-item-${item.node.id}`}
          className={classNames({
            [styles.Divider]: index > 0,
          })}
        >
          <Teaser
            component={TEASER_LAYOUT_TEXT}
            contentBoxType={contentBoxData.contentBoxType}
            {...ensureTeaserInterfaceItem(item)}
          />
        </div>
      ))}
    </>
  );
};

const getContentBoxType = ({
  component = CONTENT_SOURCE_MOST_READ,
}: ContentBoxProps) => component;

const getTitleByProps = ({ node, component }: ContentBoxProps) =>
  node.title || (component === CONTENT_SOURCE_MOST_READ ? MOST_READ_TITLE : '');

const MostRead = ContentBoxFactory({
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
  },
  TeaserGridRenderer: () => TeaserRenderer,
  SingleTeaser: Teaser,
  getContentBoxRowGridOptions: noop,
  teaserLayout: null,
  Skeleton,
  publication: PUBLICATION_GROUP_PME,
  contentBoxType: getContentBoxType,
  title: getTitleByProps,
});

export default MostRead;
