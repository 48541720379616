import React, { ReactElement } from 'react';
import Logo from '../../../Logo';
import RefetchGqlDataLink from '../../../RefetchGqlDataLink';
import styles from './styles.legacy.css';

const HeaderLogo = (): ReactElement => {
  return (
    <div className={styles.LinkWrapper}>
      <RefetchGqlDataLink path="/" className={styles.Logo}>
        <Logo type="logo/black" />
      </RefetchGqlDataLink>
    </div>
  );
};

export default HeaderLogo;
