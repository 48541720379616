import React from 'react';
import classNames from 'classnames';
import MinistageSocialMediaIcon from './components/MinistageSocialMediaIcon';
import {
  TRACKING_CLASS_MINISTAGE_SOCIAL_MEDIA_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../../../shared/constants/tracking';
import {
  SOCIAL_MEDIA_LINK_FACEBOOK,
  SOCIAL_MEDIA_LINK_LINKEDIN,
  SOCIAL_MEDIA_LINK_TWITTER,
} from '../../../../../../constants';
import sections from '../../../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import { MinistageSocialMediaPops } from './typings';

const socialIconItems = [
  {
    type: 'Facebook',
    link: SOCIAL_MEDIA_LINK_FACEBOOK,
  },
  {
    type: 'Twitter',
    link: SOCIAL_MEDIA_LINK_TWITTER,
  },
  {
    type: 'Linkedin',
    link: SOCIAL_MEDIA_LINK_LINKEDIN,
  },
];

const renderIcons = (icon, key) => (
  <li key={`ministage-social-media-icon-${key}`}>
    <MinistageSocialMediaIcon type={icon.type} link={icon.link} />
  </li>
);

const MinistageSocialMedia = ({
  ministageSocialMedia,
}: MinistageSocialMediaPops) => {
  if (!ministageSocialMedia) {
    return null;
  }

  return (
    <div
      className={classNames(
        TRACKING_CLASS_PARAGRAPH,
        TRACKING_CLASS_MINISTAGE_SOCIAL_MEDIA_PARAGRAPH,
        sections.Section,
        styles.Wrapper,
      )}
    >
      <div className={classNames(sections.Container, styles.Container)}>
        <div className={styles.Content}>
          {ministageSocialMedia.headline && (
            <div className={styles.Headline}>
              {ministageSocialMedia.headline}
            </div>
          )}
          {ministageSocialMedia.subhead && (
            <p className={styles.Subhead}>{ministageSocialMedia.subhead}</p>
          )}
        </div>
        <ul className={styles.IconWrapper}>
          {socialIconItems.map(renderIcons)}
        </ul>
      </div>
    </div>
  );
};

export default MinistageSocialMedia;
