import React from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import locationStateSelector from '../../../../../shared/selectors/locationStateSelector';
import withPagePager from '../../../../../shared/decorators/withPagePager';
import withParams from '../../../../../shared/decorators/withParams';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withHelmet from '../../../../shared/decorators/withHelmet';
import Helmet from '../../components/Helmet';
import SearchForm from '../../components/SearchForm';
import EmptyResult from './components/EmptyResult';
import SearchResult from './components/SearchResult';
import SortOrder from './components/SortOrder';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../../components/Pager';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { SITE_TITLE } from '../../../App/constants';
import { SEARCH_FORM_THEME_BLUE } from '../../components/SearchForm/constants';
import { PAGE_SIZE } from './constants';
import styles from './styles.legacy.css';
import { SearchProps } from './typings';

type SearchPropsInner = SearchProps & {
  query: string;
};

const loadingJsx = (
  <div className={styles.EmptyResultWrapper}>
    <h2 className={styles.EmptyResultTitle}>Recherche en cours...</h2>
  </div>
);

const Search = ({ data, page }: SearchPropsInner) => {
  const loading = useSelector((state) => locationStateSelector(state).loading);
  const { query } = useParams();
  const isSearch = useMatch('/search/:query');
  const searchLoading = loading && isSearch;
  const globalSearch = data?.environment?.globalSearch || null;

  return (
    <div className={classNames(styles.OuterWrapper, 'search-page')}>
      <div className={styles.Background}>
        <div className={styles.ContentWrapper}>
          <Helmet
            titleTemplate={`%s - ${SITE_TITLE}`}
            title={query}
            socialMetaValues={{
              field_short_title: query,
              field_short_description: query,
              field_heroimage: '',
              field_lead: query,
            }}
          />
          <div className={styles.Header}>
            <div className={styles.Subtitle}>Résultats de recherche pour:</div>
            <SearchForm
              minQueryLength={2}
              initialQuery={query}
              placeholder="Mot-clé"
              theme={SEARCH_FORM_THEME_BLUE}
              focusOnMount
            />
          </div>
        </div>
      </div>
      <div className={styles.Wrapper}>
        {(searchLoading &&
          (!globalSearch || !globalSearch.count || globalSearch.count < 1) &&
          loadingJsx) ||
          null}
        {(!searchLoading &&
          (!globalSearch || !globalSearch.count || globalSearch.count < 1) && (
            <EmptyResult />
          )) ||
          null}
      </div>
      {(globalSearch && globalSearch.count && globalSearch.count > 0 && (
        <>
          <div className={styles.Wrapper}>
            <SortOrder />
          </div>
          <SearchResult list={globalSearch} page={page} />
          <Pager
            component={PAGER_TYPE_PAGE_LOADER}
            currentPage={page}
            itemsCount={globalSearch.count}
            itemsPerPage={PAGE_SIZE}
            key={`search-result-pager-${query}`}
          />
        </>
      )) ||
        null}
    </div>
  );
};

export default compose(
  withParams,
  withPagePager,
  withHelmet({
    getNodesCount: (mapProps) =>
      mapProps?.data?.environment?.globalSearch?.count || 0,
    pageSize: PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps) =>
      mapProps?.data?.environment?.globalSearch?.edges || [],
  }),
  withAppNexus({
    parseTrackingData: (props) =>
      parseTrackingData({
        ...props,
        articleType: 'LandingPage',
        contentType: 'Search',
        articleId: `search_${props.query}`,
        subsection: props.query,
      }),
  }),
)(Search);
