/* istanbul ignore file */

import classNames from 'classnames';
import ListicleItemParagraphFactory from '../../../../../../../common/components/Paragraphs/components/ListicleItemParagraph/factory';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const ListicleItemParagraph = ListicleItemParagraphFactory({
  styles: {
    ContentBox: classNames(grid.ColXs24, grid.ColSm14),
    ImageBox: classNames(grid.ColXs24, grid.ColSm10, styles.ImageBox),
    InnerWrapper: classNames(grid.ColOffsetXl4, grid.ColXs24, grid.ColXl16),
    Wrapper: styles.Wrapper,
    Title: styles.Title,
    Content: styles.Content,
    Footer: styles.Footer,
    Even: styles.Even,
    Odd: styles.Odd,
    ListicleItemWrapper: styles.ListicleItemWrapper,
    ListicleItemInnerWrapper: styles.ListicleItemInnerWrapper,
  },
});

export default ListicleItemParagraph;
