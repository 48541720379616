import BrandReport from './components/BrandReport';
import Sponsors from './components/Sponsors';
import createComponentSwitch from '../../../../shared/decorators/componentSwitch';

const Sponsor = createComponentSwitch({
  sponsors: Sponsors,
  brandReport: BrandReport,
});

export default Sponsor;
