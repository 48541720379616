import { gql } from '@apollo/client';
import { keywordListFragment } from 'Keywords/components/KeywordList/fragments';

export const GET_KEYWORD_LISTING = gql`
  query KeywordListing($searchString: String!, $publication: PublicationEnum) {
    environment(publication: $publication) {
      keywordsByChar(searchString: $searchString, limit: 700) {
        ...KeywordListFragment
      }
      routeByPath(path: "keywords") {
        canonical
        preferred
        object {
          ... on LandingPage {
            publication
            id
            nid
            gcid
            title
            shortTitle
            preferredUri
            metaCanonicalUrl
            createDate
            sponsor {
              id
              title
            }
            channel {
              id
              title
            }
          }
        }
      }
    }
  }

  ${keywordListFragment}
`;
