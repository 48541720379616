/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { GET_SPONSORS } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: () => {
    return {
      query: GET_SPONSORS,
      variables: {
        path: 'brandreport',
        publication: 'PME',
        additionalPublications: [],
      },
    };
  },
};
