import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { getRestrictedClassName } from '../../../../../../../shared/helpers/withHelmet';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import EditButtons from '../../../../components/EditButtons';
import Paragraphs from '../../../../components/Paragraphs';
import { PAGESCREEN_DEFAULT_TYPE } from '../../constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import { PageScreenDefaultProps } from './typings';

const PageScreenDefault = ({
  pageScreen,
}: PageScreenDefaultProps): ReactElement => (
  <div className={`page-screen-default ${styles.Wrapper}`}>
    <EditButtons
      editContentUri={pageScreen.editContentUri}
      editRelationUri={pageScreen.editRelationUri}
      cloneContentUri={pageScreen.cloneContentUri}
    />

    <div className={sections.Section}>
      <div className={sections.Container}>
        <div className={grid.Row}>
          <div className={grid.ColXs24}>
            <Breadcrumbs
              pageUrl={pageScreen.preferredUri}
              items={pageScreen.activeMenuTrail}
            />
            {pageScreen.title && (
              <h1 itemProp="headline">
                <div className={styles.Title}>{pageScreen.title}</div>
              </h1>
            )}
          </div>
        </div>
      </div>
      {pageScreen.lead && (
        <div className={sections.Container}>
          <div className={grid.Row}>
            <div className={grid.ColXs24}>
              <p className={styles.Lead}>{pageScreen.lead}</p>
            </div>
          </div>
        </div>
      )}
    </div>

    <div className={getRestrictedClassName(pageScreen.__typename)}>
      <Paragraphs
        pageBody={pageScreen.body}
        colStyle={classNames(grid.ColMd18, grid.ColXl17)}
        origin={PAGESCREEN_DEFAULT_TYPE}
        isAdSuppressed={pageScreen?.channel?.suppressAds}
      />
    </div>
  </div>
);

export default PageScreenDefault;
