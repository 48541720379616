/* istanbul ignore file */

import React from 'react';
import { compose } from 'recompose';
import alertUnsubscribeFactory from '../../../../../common/screens/AlertsUnsubscribe/factory';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withParams from '../../../../../shared/decorators/withParams';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import { setLoading, setScreenReady } from '../../../../shared/actions/route';
import Link from '../../../../../common/components/Link';
import Img from '../../components/Img';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoginForm from '../AlertsProfile/components/LoginForm';
import styles from './styles.legacy.css';
import CheckmarkSVG from '../../assets/graphics/checkmark.svg';

const AlertsUnsubscribe = alertUnsubscribeFactory({
  styles: {
    AlertsUnsubscribeWrapper: styles.AlertsUnsubscribeWrapper,
    Icon: styles.Icon,
    Text: styles.Text,
    Wrapper: '',
    LoginWrapper: styles.LoginWrapper,
  },
  successText: 'Vous vous êtes désabonné.e de toutes vos alertes e-mail.',
  loginText: 'Veuillez vous connecter pour ajouter des thèmes à suivre',
  LoadingSpinner,
  LoginForm,
  checkmarkIcon: <Img url={CheckmarkSVG} addClass={styles.Img} />,
  button: (
    <Link path="/">
      <button className={styles.Button}>Vers la page d’accueil</button>
    </Link>
  ),
  setLoading,
  setScreenReady,
});

export default compose(
  withParams,
  withAppNexus({
    parseTrackingData: (props) =>
      parseTrackingData({
        ...props,
        articleType: 'LandingPage',
      }),
  }),
)(AlertsUnsubscribe);
