import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { testLog } from '../../../../../../../shared/tests/helpers';
import locationStateSelector from '../../../../../../../shared/selectors/locationStateSelector';
import navigationStateSelector from '../../../../../../../shared/selectors/navigationStateSelector';
import { setNavigationVisible as setNavigationVisibleAction } from '../../../../../../../shared/actions/navigation';
import Link from '../../../../../../../common/components/Link';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import Icon from '../../../Icon';
import SearchForm from '../../../SearchForm';
import ExpansionPanelMenu from './components/ExpansionPanelMenu';
import ExpansionPanelSubMenu from './components/ExpansionPanelSubMenu';
import MenuLinkItem from './components/MenuLinkItem';
import {
  NEWSLETTER_TRACKING_PARAMS,
  NEWSLETTER_URL,
  SUBSCRIPTION_TRACKING_PARAMS,
  SUBSCRIPTION_URL,
} from '../../../../constants';
import { SEARCH_FORM_THEME_MENU } from '../../../SearchForm/constants';
import { TYPE_NAVIGATION_MENU_FADEOUT } from '../../constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { NavigationMenuProps } from './typings';

type NavigationMenuPropsInner = NavigationMenuProps & {
  routePathname: string;
  activePublication: string;
  setNavigationVisible: (visibleNavigation: string) => void;
  visibleNavigation: string;
};

type MenuProps = {
  menu: Menu;
  closeNavigation: (KeyboardEvent) => void;
};

type SubMenusProps = {
  menu: Menu;
  closeNavigation: (KeyboardEvent) => void;
};

type SubmenuProps = {
  entry: MenuTreeItem;
  closeNavigation: (KeyboardEvent) => void;
};

const Menu = ({ menu, closeNavigation }: MenuProps) => {
  if (
    (menu?.links?.edges?.length || 0) <= 0 ||
    !Array.isArray(menu?.links?.edges)
  ) {
    return null;
  }

  return (
    <nav
      className={grid.Container}
      data-testid="navigation-menu-navigation-wrapper"
    >
      <ul className={classNames(styles.MainNavList, grid.Row)}>
        <SubMenus menu={menu} closeNavigation={closeNavigation} />
      </ul>
    </nav>
  );
};

export const SubMenus = ({ menu, closeNavigation }: SubMenusProps) => {
  if (!menu || !menu.links || !menu.links.edges || !menu.links.edges.length) {
    return null;
  }

  return (
    <>
      {menu.links.edges.map(({ node }, index) => {
        const hasSubtree = (node?.subtree?.edges?.length || 0) > 0;

        return (
          <li
            key={index}
            className={classNames(grid.ColXs24, {
              [styles.SubMenuWrapper]: hasSubtree,
              [styles.NavListItem]: !hasSubtree,
              [grid.ColSm8]: hasSubtree,
              [grid.ColMd6]: hasSubtree,
            })}
          >
            {(hasSubtree && (
              <>
                <div
                  data-testid="navigation-menu-mobile-submenu"
                  className={grid.HiddenSmUp}
                >
                  <ExpansionPanelSubMenu
                    title={node?.link?.label}
                    isOpen={true}
                    toggleOnChildrenClick={false}
                  >
                    <SubMenu entry={node} closeNavigation={closeNavigation} />
                  </ExpansionPanelSubMenu>
                </div>
                <div
                  data-testid="navigation-menu-desktop-submenu"
                  className={grid.HiddenSmDown}
                >
                  <div className={styles.LinkWrapper}>
                    <Link className={styles.NavHeaderLink} {...node?.link} />
                  </div>
                  <SubMenu entry={node} closeNavigation={closeNavigation} />
                </div>
              </>
            )) || (
              <TestFragment data-testid="navigation-menu-without-submenu">
                <MenuLinkItem node={node} closeNavigation={closeNavigation} />
              </TestFragment>
            )}
          </li>
        );
      })}
    </>
  );
};

export const SubMenu = ({ entry, closeNavigation }: SubmenuProps) => {
  if (
    !entry ||
    !entry.subtree ||
    !entry.subtree.edges ||
    !entry.subtree.edges.length
  ) {
    return null;
  }

  return (
    <ul className={styles.SubNavList} data-testid="navigation-menu-submenu">
      {entry.subtree.edges.map(({ node }, index) => (
        <li key={index} className={styles.LinkWrapper}>
          <MenuLinkItem node={node} closeNavigation={closeNavigation} />
        </li>
      ))}
    </ul>
  );
};

class NavigationMenu extends React.Component<NavigationMenuPropsInner> {
  constructor(props) {
    super(props);
  }

  closeNavigation = (event: KeyboardEvent) => {
    if (
      this.props.visibleNavigation === null ||
      event.metaKey ||
      event.shiftKey ||
      event.altKey ||
      event.ctrlKey
    ) {
      return;
    }

    testLog('navigation closed');
    this.props.setNavigationVisible(null);
  };

  closeMenuHandler = (event) => {
    if (event?.keyCode === 27) {
      this.closeNavigation(event);
    }
  };

  componentDidMount() {
    global.addEventListener('keydown', this.closeMenuHandler);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.routePathname !== nextProps.routePathname) {
      // Always turn off navigation when this location changed
      this.props.setNavigationVisible(null);
    }

    if (nextProps.visibleNavigation === null) {
      return true;
    }

    return this.props.visibleNavigation !== nextProps.visibleNavigation;
  }

  componentWillUnmount() {
    global.removeEventListener('keydown', this.closeMenuHandler);
  }

  render() {
    const { setNavigationVisible, navigationMenu }: NavigationMenuPropsInner =
      this.props;

    return (
      (
        <div
          className={styles.NavigationMenuWrapper}
          data-testid="navigation-wrapper"
        >
          <div>
            <div className={styles.Container}>
              <button
                data-testid="navigation-close-button"
                onClick={() => {
                  testLog('navigation closed');
                  setNavigationVisible(TYPE_NAVIGATION_MENU_FADEOUT);
                }}
                className={styles.ActionIconClose}
                aria-label="Fermer le menu"
              >
                <Icon type="IconXMark" addClass={styles.IconXMark} />
              </button>
              <div className={styles.SearchFormWrapper}>
                <SearchForm
                  placeholder="Rechercher"
                  focusOnMount
                  focusOnMountDelay={300}
                  theme={SEARCH_FORM_THEME_MENU}
                />
              </div>
              <div className={styles.SubscribeLinkWrapper}>
                <Link
                  className={styles.SubscribeLink}
                  path={SUBSCRIPTION_URL + SUBSCRIPTION_TRACKING_PARAMS}
                  onClick={() =>
                    setNavigationVisible(TYPE_NAVIGATION_MENU_FADEOUT)
                  }
                  label="S'abonner"
                />

                <Link
                  className={styles.SubscribeLink}
                  path={NEWSLETTER_URL + NEWSLETTER_TRACKING_PARAMS}
                  onClick={() =>
                    setNavigationVisible(TYPE_NAVIGATION_MENU_FADEOUT)
                  }
                  label="Newsletters"
                />
              </div>
            </div>

            {(navigationMenu && (
              <TestFragment data-testid="navigation-menu-wrapper">
                <ExpansionPanelMenu
                  isOpen
                  ariaLabel="PME Menu toggle"
                  toggleOnChildrenClick={false}
                >
                  <Menu
                    menu={navigationMenu}
                    closeNavigation={this.closeNavigation}
                  />
                </ExpansionPanelMenu>
              </TestFragment>
            )) ||
              null}
          </div>
        </div>
      ) || null
    );
  }
}

const mapStateToProps = (state) => ({
  routePathname:
    locationStateSelector(state).locationBeforeTransitions.pathname,
  activePublication: navigationStateSelector(state).activePublication,
  visibleNavigation: navigationStateSelector(state).visibleNavigation,
});

const mapDispatchToProps = {
  setNavigationVisible: setNavigationVisibleAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NavigationMenu,
);
