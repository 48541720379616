import React from 'react';
import TeaserMImageTile from '../TeaserMImageTile';
import { TEASER_M_BRANDREPORT_IDENTIFIER } from '../../../../constants';
import { TeaserProps } from '../../../../typings';

const TeaserMBrandReport = (props: TeaserProps) => (
  <TeaserMImageTile
    {...props}
    trackingSelector={TEASER_M_BRANDREPORT_IDENTIFIER}
  />
);

export default TeaserMBrandReport;
