import { compose } from 'redux';
import { STRUCTURED_META_DATA } from '../helpers/metaData';
import withHelmetFactory from '../../../shared/decorators/withHelmetFactory';
import Helmet from '../../screens/App/components/Helmet';
import { WithHelmet } from './withHelmet';
import { PUBLICATION_PME_SEO_TITLE as PUBLISHER } from '../../../shared/constants/publications';
import pmeLogo from '../../screens/App/assets/graphics/logo-pme.png';
export type {
  WithHelmetProps,
  WithHelmet,
} from '../../../shared/decorators/@types/withHelmetFactory';

const withHelmet = withHelmetFactory({
  Helmet,
  getPublisher: () => PUBLISHER,
  getPublisherLogo: () => `${global.locationOrigin}${pmeLogo}`,
});

const WithHelmetComponent = (props: WithHelmet) =>
  compose(
    withHelmet({
      ...props,
      structuredDefaultData: STRUCTURED_META_DATA,
    }),
  );

export default WithHelmetComponent;
