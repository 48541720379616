// sonar-disable

import React, { ComponentType } from 'react';
import { useMutation } from '@apollo/client';
import webformFactory from '../../../../../../../common/components/Paragraphs/components/WebformParagraph/factory';
import Button from '../../../ButtonWithLoading';
import Error from '../../../Error';
import Icon from '../../../Icon';
import LoadingSpinner from '../../../LoadingSpinner';
import LoginForm from '../../../LoginForm';
import AddressFieldsWrapper from './components/AddressFieldsWrapper';
import BirthdayField from './components/BirthdayField';
import FileField from './components/FileField';
import InputField from './components/InputField';
import MultiField from './components/MultiField';
import SelectField from './components/SelectField';
import SubmitButton from './components/SubmitButton';
import { SAVE_WEBFORM } from './mutations';
import { GOOGLE_RECAPTCHA_KEY } from '../../../../constants';
import styles from './styles.legacy.css';
import { WebformProps } from '../../../../../../../common/components/Paragraphs/components/WebformParagraph/typings';
import { useStableNavigate } from '../../../../../../../shared/hooks/useStableNavigateContext';

const NavigateButton = ({ path = '/', label = `Vers la page d'accueil` }) => {
  const navigate = useStableNavigate();
  return (
    <Button onClick={() => navigate(path)} clickEffect={false}>
      {label}
    </Button>
  );
};

const WebformComponent = webformFactory({
  SubmitButton,
  ErrorMessage: Error,
  FileField,
  Icon,
  InputField,
  BirthdayField,
  MultiField,
  SelectField,
  AddressFieldsWrapper,
  successCallToAction: <NavigateButton />,
  errorCallToAction: (
    <NavigateButton path="/contact" label="Vers le formulaire de contact" />
  ),
  RestrictionForm: LoginForm,
  LoadingSpinner,
  defaultSuccessTitle: 'Avec nos sincères remerciements',
  defaultErrorMessage:
    'Pour des raisons techniques, le formulaire n’a pas pu être envoyé. Veuillez s’il vous plaît réessayer plus tard ou nous contacter.',
  defaultSuccessMessage: 'Le formulaire a été envoyé avec succès.',
  restrictionFormLoginMessage:
    'Veuillez s’il vous plaît vous inscrire pour remplir ce formulaire.',
  restrictionFormSetUsernameMessage:
    'Pour remplir ce formulaire, veuillez s’il vous plaît entrer votre nom d’utilisateur.',
  appCaptchaMessage: 'Veuillez s’il vous plaît d’abord confirmer le Captcha.',
  appErrorPanelHeaderMesssage: 'Veuillez s’il vous plaît réessayer',
  reCaptchaKey: GOOGLE_RECAPTCHA_KEY,
  IconTypes: {
    errorIconType: 'IconCircleInfo',
  },
  withErrorIcon: true,
  styles: {
    SubTitle: styles.SubTitle,
    ToggleFormAppear: styles.ToggleFormAppear,
    ToggleFormAppearActive: styles.ToggleFormAppearActive,
    ToggleFormLeave: styles.ToggleFormLeave,
    ToggleFormLeaveActive: styles.ToggleFormLeaveActive,
    Description: styles.Description,
    Required: styles.Required,
    ButtonWrapper: styles.ButtonWrapper,
    Loading: styles.Loading,
    ToggleErrorAppear: styles.ToggleErrorAppear,
    ToggleErrorAppearActive: styles.ToggleErrorAppearActive,
    ToggleErrorLeave: styles.ToggleErrorLeave,
    ToggleErrorLeaveActive: styles.ToggleErrorLeaveActive,
    ErrorIcon: '',
    ErrorPanelWrapper: styles.ErrorPanelWrapper,
    ErrorPanelHeader: styles.ErrorPanelHeader,
    ErrorPanelContent: '',
    RecaptchaWrapper: styles.RecaptchaWrapper,
    SuccessIcon: '',
    SuccessWrapper: styles.SuccessWrapper,
    SuccessContent: '',
    SuccessTitle: styles.SuccessTitle,
    SuccessMessage: styles.SuccessMessage,
    ClosedContainer: styles.ClosedContainer,
    ClosedMessage: styles.ClosedMessage,
    ClosedIcon: styles.ClosedIcon,
    RichTextWrapper: styles.RichTextWrapper,
  },
});

const WebformParagraph: ComponentType<Omit<WebformProps, 'mutate'>> = (
  props,
) => {
  const [saveWebform] = useMutation(SAVE_WEBFORM);

  return <WebformComponent {...props} mutate={saveWebform} />;
};

export default WebformParagraph;
