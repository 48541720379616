/* istanbul ignore file */

import parallaxImageParagraphFactory from '../../../../../../../common/components/Paragraphs/components/ParallaxImageParagraph/factory';
import styles from './styles.legacy.css';

const ParallaxImageParagraph = parallaxImageParagraphFactory({
  styles: {
    CreditsTitle: styles.CreditsTitle,
    ImageDefault: styles.ImageDefault,
    Parallax: styles.Parallax,
  },
  language: 'fr',
});

export default ParallaxImageParagraph;
