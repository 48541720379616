import React, { ComponentType, ReactElement, memo } from 'react';
import styles from './styles.legacy.css';

const LoadingBar: ComponentType = (): ReactElement => (
  <div className={styles.LoadingIndicator}>
    <div className={styles.LoadingIndicatorItem}>
      <div className={styles.LoadingBar} />
    </div>
    <div className={styles.LoadingIndicatorItem}>
      <div className={styles.LoadingBar} />
    </div>
  </div>
);

export default memo(LoadingBar);
