import React from 'react';
import withProps from 'recompose/withProps';
import footerFactory from '../../../../../common/components/Footer/factory';
import Link from '../../../../../common/components/Link';
import Icon from '../Icon';
import Logo from '../Logo';
import SocialMediaBar from '../SocialMediaBar';
import FooterInner from './components/FooterInner';
import { GET_FOOTER } from './queries.preload';
import sections from '../../assets/styles/sections.legacy.css';
import classNames from 'classnames';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

export const FooterLogo = () => (
  <Link path="/" className={styles.Logo}>
    <Logo type="logo/white" />
  </Link>
);

const Footer = footerFactory({
  Logo: FooterLogo,
  Icon,
  SocialMediaBar,
  FooterInner,
  raschLabel: 'Ringier SA | Ringier Médias Suisse',
  morePublicationsLabel: 'publications complémentaires',
  styles: {
    Wrapper: classNames(styles.Footer, grid.HideForPrint),
    FooterHeader: styles.FooterHeader,
    LogoWrapper: styles.LogoWrapper,
    SocialMediaBarWrapper: styles.SocialMediaBarWrapper,
    PublicationSection: sections.PublicationSection,
    PublicationCollapseHeader: styles.PublicationCollapseHeader,
    PublicationCollapseHeaderCol: styles.PublicationCollapseHeaderCol,
    Disclaimer: styles.Disclaimer,
    CollapseToggleWrapper: styles.CollapseToggleWrapper,
    PublicationToggle: styles.PublicationToggle,
    PublicationToggleIsOpen: styles.PublicationToggleIsOpen,
    PublicationCollapseBody: styles.PublicationCollapseBody,
    PublicationCollapseBodyIsOpen: styles.PublicationCollapseBodyIsOpen,
    PublicationList: styles.PublicationList,
    Open: styles.Open,
    ExpansionIcon: styles.ExpansionIcon,
    ExpansionIconOpen: styles.ExpansionIconOpen,
    ListItem: styles.ListItem,
    Link: styles.Link,
  },
});

const withData = withProps(GET_FOOTER);

export default withData(Footer);
