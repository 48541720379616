import React, { ReactElement } from 'react';
import { compose } from 'recompose';
import { ensureTeaserInterface } from '../../../../../../components/Teaser/shared/helpers';
import withPagePager from '../../../../../../../../../shared/decorators/withPagePager';
import TestFragment from '../../../../../../../../../shared/tests/components/TestFragment';
import TeaserGrid from '../../../../../../components/TeaserGrid';
import Pager, {
  PAGER_TYPE_PAGE_LOADER,
} from '../../../../../../components/Pager';
import { GRID_LAYOUT_TEASER_3X2 } from '../../../../../../components/TeaserGrid/gridConfigs/constants';
import { KEYWORD_PAGE_SIZE } from '../../../../../Keywords/constants';

export type SearchResultProps = Pick<RouterProps, 'page'> & {
  list?: SearchableUnionGraphList;
};

type SearchResultPropsInner = SearchResultProps;

const SearchResult = ({ list, page }: SearchResultPropsInner): ReactElement => {
  if (!list?.edges || !Array.isArray(list.edges) || list.edges.length === 0) {
    return null;
  }
  return (
    <TestFragment data-testid="searchresult-teaserlist-wrapper">
      <TeaserGrid
        layout={GRID_LAYOUT_TEASER_3X2}
        items={ensureTeaserInterface(list.edges)}
      />
      <Pager
        key="keywords-pager"
        itemsCount={list.count || 0}
        itemsPerPage={KEYWORD_PAGE_SIZE}
        currentPage={page}
        component={PAGER_TYPE_PAGE_LOADER}
      />
    </TestFragment>
  );
};

export default compose(withPagePager)(SearchResult);
