import React from 'react';
import ButtonWithLoading from '../../../../../ButtonWithLoading';
import styles from './styles.legacy.css';
import { SubmitButtonProps } from './typings';

const SubmitButton = ({ children, loading }: SubmitButtonProps) => (
  <div className={styles.SubmitButtonWrapper}>
    <ButtonWithLoading loading={loading}>{children}</ButtonWithLoading>
  </div>
);

export default SubmitButton;
