import React from 'react';

const SocialLinkedin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    aria-hidden={true}
  >
    <g>
      <path d="M11.4 12H7.8v10.7h3.6V12zm.2-3.3c0-1-.8-1.9-2-1.9s-2.1.8-2.1 1.9c0 1 .8 1.9 2 1.9 1.3 0 2.1-.8 2.1-1.9zm5.4 4.9V12h-3.6v10.7H17v-6c0-.3 0-.6.1-.9.3-.6.9-1.3 1.9-1.3 1.3 0 1.8 1 1.8 2.4v5.7h3.6v-6.1c0-3.3-1.8-4.8-4.2-4.8-1.8.1-2.7 1.1-3.2 1.9z" />
    </g>
  </svg>
);

export default SocialLinkedin;
