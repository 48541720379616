import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../common/components/Teaser/helpers';
import {
  getFormattedPublicationDateByProps,
  getIsBottomLineVisibleByProps,
} from '../../shared/helpers';
import { TEASER_XXS_DEFAULT_IDENTIFIER } from '../../constants';
import styles from './styles.legacy.css';

const TeaserXXS = teaserFactory({
  isShortTitleHidden: true,
  isPublicationDateVisible: false,
  isAuthorVisible: getIsBottomLineVisibleByProps,
  formattedPublicationDate: getFormattedPublicationDateByProps,
  trackingTeaserHandler: withTeaserTrackingHandler,
  styles: {
    OuterWrapper: styles.OuterWrapper,
    Wrapper: classNames(TEASER_XXS_DEFAULT_IDENTIFIER, styles.Wrapper),
    ContentWrapper: styles.ContentWrapper,
    Title: styles.TeaserTitle,
    BottomLine: styles.BottomLine,
  },
});

export default TeaserXXS;
